import { useEffect, useState } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import ReactImageFallback from 'react-image-fallback'
import { useParams } from 'react-router-dom'

import loader from '../../images/loader-gif.gif'
import Logo from '../../images/ultralead-branding-dark.svg'
import CarImage from '../../images/vehicle_placeholder_lg.png'
import LeadLayout from '../../layout/LeadLayout'
import LeadDetailService from '../../services/leadDetails'

interface LeadDetailsInfo {
  comments: string
  customer: {
    address: {
      city: string
      country: string
      postal: string
      province: string
      street: string
      regioncode: string
      postal_zip: string
    }
    email: string
    first: string
    last: string
    phone: string
  }
  dealer_id: string
  email: string
  finance: {
    amount: number
    amount_borrowed: number
    credit: number
    credit_flavour: number
    down_payment: number
    estimated_credit: number
    income: number
    max_monthly: number
    method: number
    term: number
    trade: number
  }
  first_name: string
  last_name: string
  lenders: [Lender]
  similar_vehicles: [
    {
      certified_preowned_flag: boolean
      date_max: Date
      date_min: Date
      imgset_urls: string
      listing_description: string
      listing_features: string
      listing_mileage: number
      listing_price: number
      listing_stock: string
      listing_type: string
      listing_vdp_url: string
      location: {
        coordinates: [number]
        type: string
      }
      photo_urls: [string]
      va_seller_address: string
      va_seller_city: string
      va_seller_country: string
      va_seller_domains: string
      va_seller_id: string
      va_seller_makes: string
      va_seller_name: string
      va_seller_phones: string
      va_seller_state: string
      va_seller_type: string
      va_seller_websites: string
      va_seller_zip: string
      vehicle_color_exterior: string
      vehicle_color_interior: string
      vehicle_doors: string
      vehicle_drivetrain: string
      vehicle_engine: string
      vehicle_engine_cylinders: string
      vehicle_engine_size: string
      vehicle_fuel_efficiency: string
      vehicle_fuel_efficiency_city: string
      vehicle_fuel_efficiency_highway: string
      vehicle_fuel_type: string
      vehicle_make: string
      vehicle_model: string
      vehicle_style: string
      vehicle_subtitle: string
      vehicle_title: string
      vehicle_transmission: string
      vehicle_transmission_speed: string
      vin: string
    }
  ]
  source: {
    name: string
  }
  state: string
  uuid: string
  vehicle: {
    inventory_id: string
    kilometers: number
    make: string
    mileage: number
    model: string
    photo_urls: [string]
    price: number
    stock: string
    trim: string
    type: string
    ul_recommended: boolean
    vin: string
    year: number
  }
}

interface Lender {
  estimated_monthly: number
  lender: string
  program: string
  rate: number
  term: number
  term_max: number
  term_min: number
  program_type: string
}

const LeadDetails = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [leadDetails, setLeadDetails] = useState<LeadDetailsInfo>()
  let { id } = useParams()
  const getDetails = (id: any) => {
    setIsLoading(true)
    LeadDetailService.getLeadDetails(id)
      .then((resp) => resp.json())
      .then((resp) => {
        setLeadDetails(resp)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getDetails(id)
  }, [])
  const LenderData = (lender: Lender) => {
    return (
      <div>
        <span className="text-[20px] text-[#111827]">
          {lender?.lender || '-'}
        </span>
        <span className="block text-[12px] font-bold text-[#111827]">
          Program Name
        </span>
        <span className="text-[20px] text-[#111827]">
          {lender?.program || '-'}
        </span>
        <div>
          <div className="grid grid-flow-col gap-[12px]">
            {/* <div>
              <span className="block text-[12px] font-bold text-[#111827]">
                Min. Down Payment
              </span>
              <span className="text-[20px] text-[#111827]">-</span>
            </div> */}
            <div>
              <span className="block text-[12px] font-bold text-[#111827]">
                Interest Rate
              </span>
              <span className="text-[20px] text-[#111827]">
                {lender?.rate ? `${lender?.rate}%` : '-'}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const VehicleData = (vehicle: any) => {
    return (
      <div>
        <div>
          <div className="grid grid-cols-8 gap-[20px]">
            <div className="col-span-5">
              <div className="rounded-[8px] border-[3px] border-[#979797]">
                <ReactImageFallback
                  src={vehicle.photo_urls[0] || CarImage}
                  fallbackImage={CarImage}
                  initialImage={CarImage}
                  alt={`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}
                  className="m-auto h-auto max-h-[498px] w-full max-w-[762px]"
                />
              </div>
            </div>
            <div className="col-span-3">
              <div>
                <span className="  mr-[20px] text-[20px] text-[#3B2F66]">
                  {vehicle.vehicle_title}
                </span>
              </div>
              {vehicle?.listing_vdp_url && (
                <a
                  href={vehicle?.listing_vdp_url}
                  target="_blank"
                  className="pointer mt-[20px] inline-block text-[20px] leading-[40px] text-[#9475FF]"
                >
                  <BiLinkExternal />
                </a>
              )}
              <br />
              <span className="text-[20px] font-medium text-[#3B2F66]">
                <span className="font-[500]">Inventory ID:</span>{' '}
                <span className="font-[450]">{vehicle?.listing_stock}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <LeadLayout>
      <div className="relative mx-auto w-full max-w-[1136px]">
        <div className="mb-[28px] grid-rows-1 text-[36px] font-medium text-[#002257] md:hidden">
          Lead Details: <br />
          Lead# {id}
        </div>
        <div className="grid rounded-[8px] bg-white py-[20px] shadow-lg sm:grid-cols-1 md:hidden md:grid-cols-3">
          {isLoading && (
            <div className="absolute flex h-full w-full justify-center bg-white bg-opacity-70">
              <div className="mt-[100px] flex flex-col">
                <div>
                  <span className="text-[20px] text-[#3B2F66]">
                    Loading Lead Details...
                  </span>
                </div>
                <div>
                  <img
                    src={loader}
                    alt="loader"
                    className="m-auto h-[98px] w-[98px]"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-span-2 px-[20px]  ">
            <span className="text[20px] font-[700] uppercase text-[#3B2F66]">
              Requested Vehicle
            </span>
            <div>
              <ReactImageFallback
                src={
                  leadDetails?.vehicle?.photo_urls
                    ? leadDetails?.vehicle?.photo_urls[0]
                    : CarImage
                }
                fallbackImage={CarImage}
                initialImage={CarImage}
                alt={`${leadDetails?.vehicle?.year} ${leadDetails?.vehicle?.make} ${leadDetails?.vehicle?.model}`}
                className="m-auto h-auto max-h-[498px] w-full max-w-[762px]"
              />
            </div>
            <div className="col-span-1">
              <div className="grid grid-flow-row auto-rows-max gap-[20px]">
                <div>
                  <span className="text[20px] font-[700] uppercase text-[#3B2F66]">
                    Customer Information
                  </span>
                </div>

                <div>
                  <div className="grid grid-flow-col gap-[20px]">
                    <div>
                      <span className="block text-[12px] font-bold text-[#111827]">
                        First Name
                      </span>
                      <span className="text-[20px] text-[#111827]">
                        {leadDetails?.customer?.first || '-'}
                      </span>
                    </div>
                    <div>
                      <span className="block text-[12px] font-bold text-[#111827]">
                        Last Name
                      </span>
                      <span className="text-[20px] text-[#111827]">
                        {' '}
                        {leadDetails?.customer?.last || '-'}
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Email Address
                    </span>
                    <span className="break-all text-[20px] text-[#9475FF]">
                      {leadDetails?.customer?.email || '-'}
                    </span>
                  </div>
                </div>

                {leadDetails?.customer?.address?.street && (
                  <div>
                    <div>
                      <span className="block text-[12px] font-bold text-[#111827]">
                        Address
                      </span>
                      <span className="text-[20px] text-[#111827]">
                        {leadDetails?.customer?.address?.street}
                      </span>
                    </div>
                  </div>
                )}

                <div>
                  <div className="grid grid-flow-col gap-[20px]">
                    {leadDetails?.customer?.address?.city && (
                      <div>
                        <span className="block text-[12px] font-bold text-[#111827]">
                          City
                        </span>
                        <span className="text-[20px] text-[#111827]">
                          {leadDetails?.customer?.address?.city || '-'}
                        </span>
                      </div>
                    )}
                    {leadDetails?.customer?.address?.regioncode && (
                      <div>
                        <span className="block text-[12px] font-bold text-[#111827]">
                          State/Province
                        </span>
                        <span className="text-[20px] text-[#111827]">
                          {leadDetails?.customer?.address?.regioncode || '-'}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <div className="grid grid-flow-col gap-[20px]">
                    <div>
                      <span className="block text-[12px] font-bold text-[#111827]">
                        ZIP/Postal Code
                      </span>
                      <span className="text-[20px] text-[#111827]">
                        {leadDetails?.customer?.address?.postal_zip || '-'}
                      </span>
                    </div>
                    <div>
                      <span className="block text-[12px] font-bold text-[#111827]">
                        Phone Number
                      </span>
                      <span className="break-all text-[20px] text-[#9475FF]">
                        {leadDetails?.customer?.phone || '-'}
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Reported Annual Income
                    </span>
                    <span className="mr-[12px] text-[20px] text-[#111827]">
                      {leadDetails?.finance?.income
                        ? `$${new Intl.NumberFormat().format(
                            leadDetails?.finance?.income
                          )}`
                        : '-'}
                    </span>
                    {leadDetails?.finance?.income && (
                      <span className="absolute text-[12px] font-bold text-[#857E9E]">
                        {leadDetails?.vehicle?.hasOwnProperty('kilometers') &&
                        leadDetails?.vehicle?.kilometers !== null
                          ? 'CAD'
                          : 'USD'}
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Trade-in Year/Make/Model
                    </span>
                    <span className="mr-[12px] inline-block text-[20px] text-[#111827]">
                      -
                    </span>
                    <span className="inline-block text-[20px] text-[#9475FF]">
                      {/* <BiLinkExternal /> */}
                    </span>
                  </div>
                </div>

                <div>
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Trade-in Value
                    </span>
                    <span className="mr-[12px] text-[20px] text-[#111827]">
                      {leadDetails?.finance?.trade &&
                      !isNaN(parseInt(leadDetails?.finance?.trade))
                        ? `$${new Intl.NumberFormat().format(
                            leadDetails?.finance?.trade
                          )}`
                        : '$0'}
                    </span>
                    {leadDetails?.finance?.trade && (
                      <span className="absolute text-[12px] font-bold text-[#857E9E]">
                        {leadDetails?.vehicle?.hasOwnProperty('kilometers') &&
                        leadDetails?.vehicle?.kilometers !== null
                          ? 'CAD'
                          : 'USD'}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-[20px] mt-[32px] grid-cols-3 gap-[20px] break-all">
              <div className="grid grid-flow-col gap-[20px]">
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    Year
                  </span>
                  <span className="text-[20px] text-[#111827]">
                    {leadDetails?.vehicle?.year || '-'}
                  </span>
                </div>
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    Make
                  </span>
                  <span className="text-[20px] text-[#111827]">
                    {leadDetails?.vehicle?.make || '-'}
                  </span>
                </div>
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    Model
                  </span>
                  <span className="text-[20px] text-[#111827]">
                    {leadDetails?.vehicle?.model || '-'}
                  </span>
                </div>
              </div>
            </div>
            <div className="grid gap-[20px]">
              <div>
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    Trim
                  </span>
                  <span className="text-[20px] text-[#111827]">
                    {leadDetails?.vehicle?.trim || '-'}
                  </span>
                </div>
              </div>

              <div className="grid grid-flow-col gap-[20px]">
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    Mileage
                  </span>
                  <span className="text-[20px] text-[#111827]">
                    {leadDetails?.vehicle?.hasOwnProperty('kilometers') &&
                    leadDetails?.vehicle?.kilometers !== null
                      ? `${new Intl.NumberFormat().format(
                          leadDetails?.vehicle?.kilometers
                        )} KMS`
                      : leadDetails?.vehicle?.hasOwnProperty('mileage') &&
                        leadDetails?.vehicle?.mileage !== null
                      ? `${new Intl.NumberFormat().format(
                          leadDetails?.vehicle?.mileage
                        )} MI`
                      : '-'}
                  </span>
                </div>
              </div>
              <div>
                <span className="block text-[12px] font-bold text-[#111827]">
                  VIN
                </span>
                <span className="text-[20px] text-[#111827]">
                  {leadDetails?.vehicle?.vin || '-'}
                </span>
              </div>

              <div>
                <span className="block text-[12px] font-bold text-[#111827]">
                  Inventory ID
                </span>
                <span className="text-[20px] text-[#111827]">
                  {leadDetails?.vehicle?.inventory_id || '-'}
                </span>
              </div>
              <div>
                <span className="block text-[12px] font-bold text-[#111827]">
                  List Price
                </span>
                <span className="text-[20px] text-[#111827]">
                  {leadDetails?.vehicle?.price
                    ? `$${new Intl.NumberFormat().format(
                        leadDetails?.vehicle?.price
                      )}`
                    : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/**
         * Medium to large screens
         */}
        <div className="mb-[28px] hidden grid-rows-1 text-[36px] font-medium text-[#002257] md:grid">
          Lead Details
        </div>
        <div className="grid hidden rounded-[8px] bg-white py-[20px] shadow-lg sm:grid-cols-1 md:grid md:grid-cols-3">
          {isLoading && (
            <div className="absolute flex h-full w-full justify-center bg-white bg-opacity-70">
              <div className="mt-[300px] flex flex-col">
                <div>
                  <span className="text-[20px] text-[#3B2F66]">
                    Loading Lead Details...
                  </span>
                </div>
                <div>
                  <img
                    src={loader}
                    alt="loader"
                    className="m-auto h-[98px] w-[98px]"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-span-2 px-[32px] ">
            <span className="text[20px] font-bold text-[#3B2F66]">
              Lead #{id}
            </span>
            <div className="mb-[20px]">
              <ReactImageFallback
                src={
                  leadDetails?.vehicle?.photo_urls
                    ? leadDetails?.vehicle?.photo_urls[0]
                    : CarImage
                }
                fallbackImage={CarImage}
                initialImage={CarImage}
                alt={`${leadDetails?.vehicle?.year} ${leadDetails?.vehicle?.make} ${leadDetails?.vehicle?.model}`}
                className="m-auto h-auto max-h-[498px] w-full max-w-[762px]"
              />
            </div>
            <span className="text[20px] font-[700] uppercase text-[#3B2F66]">
              REQUESTED VEHICLE
            </span>
            <div className="mt-[20px] flex gap-[30px] break-all sm:grid-cols-1 md:grid-flow-col">
              <div className="grid grid-flow-col grid-rows-2 gap-[20px]">
                <div className="grid grid-flow-col gap-[20px]">
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Year
                    </span>
                    <span className="text-[20px] text-[#111827]">
                      {leadDetails?.vehicle?.year || '-'}
                    </span>
                  </div>
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Make
                    </span>
                    <span className="text-[20px] text-[#111827]">
                      {leadDetails?.vehicle?.make || '-'}
                    </span>
                  </div>
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Model
                    </span>
                    <span className="text-[20px] text-[#111827]">
                      {leadDetails?.vehicle?.model || '-'}
                    </span>
                  </div>
                </div>
                <div>
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Trim
                    </span>
                    <span className="text-[20px] text-[#111827]">
                      {leadDetails?.vehicle?.trim || '-'}
                    </span>
                  </div>
                </div>
              </div>

              <div className="grid grid-flow-col grid-rows-2 gap-[20px]">
                <div className="grid grid-flow-col gap-[20px]">
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Mileage
                    </span>
                    <span className="text-[20px] text-[#111827]">
                      {leadDetails?.vehicle?.hasOwnProperty('kilometers') &&
                      leadDetails?.vehicle?.kilometers !== null
                        ? `${new Intl.NumberFormat().format(
                            leadDetails?.vehicle?.kilometers
                          )} KMS`
                        : leadDetails?.vehicle?.hasOwnProperty('mileage') &&
                          leadDetails?.vehicle?.mileage !== null
                        ? `${new Intl.NumberFormat().format(
                            leadDetails?.vehicle?.mileage
                          )} MI`
                        : '-'}
                    </span>
                  </div>
                </div>
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    VIN
                  </span>
                  <span className="text-[20px] text-[#111827]">
                    {leadDetails?.vehicle?.vin || '-'}
                  </span>
                </div>
              </div>

              <div className="grid grid-flow-col grid-rows-2 gap-[20px]">
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    Inventory ID
                  </span>
                  <span className="text-[20px] text-[#111827]">
                    {leadDetails?.vehicle?.inventory_id || '-'}
                  </span>
                </div>
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    List Price
                  </span>
                  <span className="text-[20px] text-[#111827]">
                    {leadDetails?.vehicle?.price
                      ? `$${new Intl.NumberFormat().format(
                          leadDetails?.vehicle?.price
                        )}`
                      : '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="
          col-span-1 
          	
          break-all
          border-[1px] border-y-transparent
          border-l-slate-200
          border-r-transparent px-[32px]"
          >
            <div className="grid grid-flow-row auto-rows-max gap-[20px]">
              <div>
                <span className="text[20px] break-keep font-[700]	 uppercase text-[#3B2F66]">
                  Customer Information
                </span>
              </div>

              <div>
                <div className="grid grid-flow-col gap-[20px]">
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      First Name
                    </span>
                    <span className="text-[20px] text-[#111827]">
                      {leadDetails?.customer?.first || '-'}
                    </span>
                  </div>
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Last Name
                    </span>
                    <span className="text-[20px] text-[#111827]">
                      {' '}
                      {leadDetails?.customer?.last || '-'}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    Email Address
                  </span>
                  <span className="text-[20px] text-[#9475FF]">
                    {leadDetails?.customer?.email || '-'}
                  </span>
                </div>
              </div>

              {leadDetails?.customer?.address?.street && (
                <div>
                  <div>
                    <span className="block text-[12px] font-bold text-[#111827]">
                      Address
                    </span>
                    <span className="text-[20px] text-[#111827]">
                      {leadDetails?.customer?.address?.street}
                    </span>
                  </div>
                </div>
              )}

              <div>
                <div className="grid grid-flow-col gap-[20px]">
                  {leadDetails?.customer?.address?.city && (
                    <div>
                      <span className="block text-[12px] font-bold text-[#111827]">
                        City
                      </span>
                      <span className="text-[20px] text-[#111827]">
                        {leadDetails?.customer?.address?.city || '-'}
                      </span>
                    </div>
                  )}
                  {leadDetails?.customer?.address?.regioncode && (
                    <div>
                      <span className="block text-[12px] font-bold text-[#111827]">
                        State/Province
                      </span>
                      <span className="text-[20px] text-[#111827]">
                        {leadDetails?.customer?.address?.regioncode || '-'}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div className="grid grid-flow-col gap-[20px]">
                  <div>
                    <span className="block whitespace-pre text-[12px] font-bold text-[#111827]">
                      ZIP/Postal Code
                    </span>
                    <span className="text-[20px] text-[#111827]">
                      {leadDetails?.customer?.address?.postal_zip || '-'}
                    </span>
                  </div>
                  <div>
                    <span className=" block whitespace-pre text-[12px] font-bold text-[#111827]">
                      Phone Number
                    </span>
                    <span className="text-[20px] text-[#9475FF]">
                      {leadDetails?.customer?.phone || '-'}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    Reported Annual Income
                  </span>
                  <span className="mr-[12px] text-[20px] text-[#111827]">
                    {leadDetails?.finance?.income
                      ? `$${new Intl.NumberFormat().format(
                          leadDetails?.finance?.income
                        )}`
                      : '-'}
                  </span>
                  {leadDetails?.finance?.income && (
                    <span className="absolute text-[12px] font-bold text-[#857E9E]">
                      {leadDetails?.vehicle?.hasOwnProperty('kilometers') &&
                      leadDetails?.vehicle?.kilometers !== null
                        ? 'CAD'
                        : 'USD'}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    Trade-in Year/Make/Model
                  </span>
                  <span className="mr-[12px] inline-block text-[20px] text-[#111827]">
                    -
                  </span>
                  <span className="inline-block text-[20px] text-[#9475FF]">
                    {/* <BiLinkExternal /> */}
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span className="block text-[12px] font-bold text-[#111827]">
                    Trade-in Value
                  </span>
                  <span className="mr-[12px] text-[20px] text-[#111827]">
                    {leadDetails?.finance?.trade &&
                    !isNaN(parseInt(leadDetails?.finance?.trade))
                      ? `$${new Intl.NumberFormat().format(
                          leadDetails?.finance?.trade
                        )}`
                      : '$0'}
                  </span>
                  {leadDetails?.finance?.trade && (
                    <span className="absolute text-[12px] font-bold text-[#857E9E]">
                      {leadDetails?.vehicle?.hasOwnProperty('kilometers') &&
                      leadDetails?.vehicle?.kilometers !== null
                        ? 'CAD'
                        : 'USD'}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[20px] grid gap-[25px] rounded-[8px] bg-white p-[20px] shadow-lg sm:grid-cols-1 md:min-h-[616px] md:grid-cols-4">
          <div className="col-span-3 hidden rounded-[20px] bg-[#F6F6F6] px-[20px] py-[32px] md:col-span-1 md:block">
            <span className="font-CircularStd text-[20px] font-[700] uppercase text-[#3B2F66]">
              Comments
            </span>

            <div className="mt-[30px] grid grid-flow-row auto-rows-max gap-[20px]">
              <div>
                <span className="block text-[12px] font-bold text-[#111827]">
                  Down Payment
                </span>
                <span className="text-[20px] text-[#111827]">
                  {leadDetails?.finance?.down_payment
                    ? `$${new Intl.NumberFormat().format(
                        leadDetails?.finance?.down_payment
                      )}`
                    : '-'}
                </span>
              </div>
              <div>
                <span className="block text-[12px] font-bold text-[#111827]">
                  Requested Monthly Payment
                </span>
                <span className="text-[20px] text-[#111827]">
                  {leadDetails?.finance?.max_monthly
                    ? `$${new Intl.NumberFormat().format(
                        leadDetails?.finance?.max_monthly
                      )}`
                    : '-'}
                </span>
              </div>
              <div>
                <span className="block text-[12px] font-bold text-[#111827]">
                  Reported Credit Range
                </span>
                <span className="text-[20px] text-[#111827]">
                  {leadDetails?.finance?.credit || '-'}
                </span>
              </div>
              <div className="h-[250px] overflow-scroll">
                <div
                  className="text-[20px] text-[#111827]"
                  dangerouslySetInnerHTML={{
                    __html: leadDetails?.comments || '',
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div
            className="col-span-3 rounded-[20px] border-[8px] border-[#CBC3BE] 
            bg-gradient-to-b from-[#ebe6e3] to-[#FAF7F5]
            py-[32px] 
            md:px-[20px]"
          >
            <img src={Logo} alt="Logo" className="m-auto md:m-0" />
            <div
              className="my-[30px] flex grid grid-cols-3 justify-between border-[2px] border-x-transparent border-y-[#3B2F66]
            bg-[#D4C8FF] 
            px-[10px] 
            py-[29px] 
            md:px-[19px] 
            md:py-[36px] 
            "
            >
              <div>
                <span className="block text-[12px] font-bold text-[#3B2F66] md:text-[16px]">
                  Rate:
                </span>
                <span className="block font-CircularXX text-[20px]	font-normal text-[#3B2F66] md:text-[32px]">
                  {!leadDetails?.lenders?.length
                    ? ''
                    : leadDetails?.lenders?.length
                    ? leadDetails?.lenders[0]?.rate + '%'
                    : '-'}
                </span>
              </div>
              <div>
                <span className="block text-[12px] font-bold text-[#3B2F66] md:text-[16px]">
                  Term:
                </span>
                <span className="block font-CircularXX	text-[20px]	font-normal text-[#3B2F66] md:text-[32px]">
                  {!leadDetails?.lenders?.length
                    ? ''
                    : leadDetails?.finance?.term
                    ? `${new Intl.NumberFormat().format(
                        leadDetails?.finance?.term
                      )} MO.`
                    : '-'}
                </span>
              </div>
              <div>
                <span className="block text-[12px] font-bold text-[#3B2F66] md:text-[16px]">
                  Estimated Payment:
                </span>
                <span className="block font-CircularXX	text-[20px]	font-normal text-[#3B2F66] md:text-[32px]">
                  {!leadDetails?.lenders?.length
                    ? ''
                    : leadDetails?.lenders?.length
                    ? `$${new Intl.NumberFormat().format(
                        leadDetails?.lenders[0]?.estimated_monthly
                      )}`
                    : '-'}
                </span>
              </div>
              {!leadDetails?.lenders?.length && (
                <div className="col-span-3 mb-[40px] mt-[40px] text-center text-[20px]">
                  There are no lender programs for this vehicle.
                </div>
              )}
            </div>
            <div className="flex grid-cols-3 gap-[20px] px-[5px] md:px-[13px]">
              <div className="grid  grid-cols-2 gap-x-[20px] gap-y-[8px] px-[5px] md:px-[13px]">
                <div>
                  <span className="text-[12px] font-bold text-[#111827]">
                    Amount Borrowed
                  </span>
                </div>
                <div>
                  <span className="text-[12px] font-bold text-[#111827]">
                    Down Payment
                  </span>
                </div>
                <div>
                  <span className="text-[16px] text-[#111827] md:text-[20px]">
                    {leadDetails?.finance?.amount_borrowed
                      ? `$${new Intl.NumberFormat().format(
                          leadDetails?.finance?.amount_borrowed
                        )}`
                      : '-'}
                  </span>
                </div>
                <div>
                  <span className="text-[16px] text-[#111827] md:text-[20px]">
                    {leadDetails?.finance?.down_payment
                      ? `$${new Intl.NumberFormat().format(
                          leadDetails?.finance?.down_payment
                        )}`
                      : '-'}
                  </span>
                </div>
                <div>
                  <span className="text-[12px] font-bold text-[#111827]">
                    Estimated Monthly Payment
                  </span>
                </div>
                <div>
                  <span className="text-[12px] font-bold text-[#111827]">
                    Interest Rate
                  </span>
                </div>
                <div>
                  <span className="text-[16px] text-[#111827] md:text-[20px]">
                    {leadDetails?.lenders?.length
                      ? `$${new Intl.NumberFormat().format(
                          leadDetails?.lenders[0]?.estimated_monthly
                        )}`
                      : '-'}
                  </span>
                </div>
                <div>
                  <span className="text-[16px] text-[#111827] md:text-[20px]">
                    {leadDetails?.lenders?.length
                      ? leadDetails?.lenders[0]?.rate + '%'
                      : '-'}
                  </span>
                </div>
                <div>
                  <span className="text-[12px] font-bold text-[#111827]">
                    Term
                  </span>
                </div>
                <div>
                  <span className="text-[12px] font-bold text-[#111827]">
                    Lender
                  </span>
                </div>
                <div>
                  <span className="text-[16px] text-[#111827] md:text-[20px]">
                    {leadDetails?.lenders?.length
                      ? `${new Intl.NumberFormat().format(
                          leadDetails?.lenders[0]?.term
                        )} MO.`
                      : '-'}
                  </span>
                </div>
                <div>
                  <span className="text-[16px] text-[#111827] md:text-[20px]">
                    {leadDetails?.lenders?.length
                      ? leadDetails?.lenders[0]?.lender
                      : '-'}
                  </span>
                </div>

                <div>
                  <span className="text-[12px] font-bold text-[#111827]">
                    Estimated Credit Range
                  </span>
                </div>
                <div>
                  <span className="text-[12px] font-bold text-[#111827]">
                    Program Name
                  </span>
                </div>
                <div>
                  <span className="text-[16px] text-[#111827] md:text-[20px]">
                    {leadDetails?.finance?.estimated_credit
                      ? Math.round(
                          leadDetails?.finance?.estimated_credit -
                            leadDetails?.finance?.estimated_credit * 0.02
                        ) +
                        '-' +
                        Math.round(
                          leadDetails?.finance?.estimated_credit +
                            leadDetails?.finance?.estimated_credit * 0.02
                        )
                      : '-'}
                  </span>
                </div>
                <div>
                  <span className="text-[16px] text-[#111827] md:text-[20px]">
                    {leadDetails?.lenders?.length
                      ? leadDetails?.lenders[0]?.program
                      : '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3 min-h-[510px] rounded-[20px] bg-[#F6F6F6] px-[20px] py-[32px] md:col-span-1 md:hidden">
            <span className="font-CircularStd text-[20px] font-[700] uppercase text-[#3B2F66]">
              Comments
            </span>

            <div className="mt-[30px] grid grid-flow-row auto-rows-max gap-[20px]">
              <div>
                <span className="block text-[12px] font-bold text-[#111827]">
                  Down Payment
                </span>
                <span className="text-[20px] text-[#111827]">
                  {leadDetails?.finance?.down_payment
                    ? `$${new Intl.NumberFormat().format(
                        leadDetails?.finance?.down_payment
                      )}`
                    : '-'}
                </span>
              </div>
              <div>
                <span className="block text-[12px] font-bold text-[#111827]">
                  Requested Monthly Payment
                </span>
                <span className="text-[20px] text-[#111827]">
                  {leadDetails?.finance?.max_monthly
                    ? `$${new Intl.NumberFormat().format(
                        leadDetails?.finance?.max_monthly
                      )}`
                    : '-'}
                </span>
              </div>
              <div>
                <span className="block text-[12px] font-bold text-[#111827]">
                  Reported Credit Range
                </span>
                <span className="text-[20px] text-[#111827]">
                  {leadDetails?.finance?.credit || '-'}
                </span>
              </div>
              <div className="h-[250px] overflow-scroll">
                <span
                  className="text-[20px] text-[#111827]"
                  dangerouslySetInnerHTML={{
                    __html: leadDetails?.comments || '',
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
        {leadDetails?.lenders?.length > 0 && (
          <div className="mt-[20px] rounded-[8px] bg-white px-[20px] pb-[40px] pt-[20px] shadow-lg">
            <span className="font-CircularStd text-[20px] font-[700] uppercase text-[#3B2F66]">
              ULTRALEAD ALTERNATIVE QUALIFYING LENDERS
            </span>
            <div className="mt-[24px] grid justify-between gap-x-[33px] gap-y-[33px] sm:grid-cols-2 md:grid-cols-4">
              {leadDetails?.lenders?.map((lender) => LenderData(lender))}
            </div>
          </div>
        )}
        {leadDetails?.similar_vehicles?.length > 0 && (
          <div className="mt-[20px] rounded-[8px] bg-white px-[20px] pb-[40px] pt-[20px] shadow-lg">
            <span className="font-CircularStd text-[20px] font-[700] uppercase text-[#3B2F66]">
              ALTERNATIVE ULTRALEAD QUALIFYING VEHICLES IN INVENTORY
            </span>
            <div className="mt-[24px] grid-cols-1 grid-cols-2 justify-between gap-x-2 gap-y-[33px] sm:grid md:grid">
              {leadDetails?.similar_vehicles?.map((vehicle) =>
                VehicleData(vehicle)
              )}
            </div>
          </div>
        )}
      </div>
    </LeadLayout>
  )
}

export default LeadDetails
