import { useEffect, useMemo, useState } from 'react'
import type React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import LoadingSpinner from './components/Loader'
import * as Constants from './constants/constants'
import { UserContext } from './context/UserContext'
import AccountSettings from './pages/AccountSettings'
import SignIn from './pages/Auth/SignIn'
import DealerResources from './pages/DealerResources'
import LeadDetails from './pages/LeadDetails'
import LeadLog from './pages/LeadLog'
import ManageBilling from './pages/ManageBilling'
import ManageUsers from './pages/ManageUsers'
import RedirectPage from './pages/Redirect'
import UsersService from './services/users'
import './App.css'

type DealerElement = {
  icon: string
  label: string
  token: string
  value: string
}

const App: React.FC = () => {
  const user = JSON.parse(sessionStorage.getItem('user')!)
  const [userData, setUserData] = useState({})
  const [isLoadingUserData, setIsLoadingUserData] = useState(true)
  // BUG: Fix unnecessary amount of re-renders of app component
  const userValues = useMemo(
    () => ({ setUserData, userData }),
    [userData, setUserData]
  )
  useEffect(() => {
    //Load dealers list if page is reloaded
    if (user) {
      UsersService.getUserAccount(user.uid)
        .then((resp) => resp.json())
        .then((resp) => {
          const user_data = resp.user
          user_data.uid = resp.user.uuid
          const selectedDealer = JSON.parse(
            sessionStorage.getItem('selectedDealer')
          )
          if (selectedDealer && selectedDealer !== undefined) {
            user_data.dealer = selectedDealer.value
            user_data.dealer_name = selectedDealer.label
            user_data.token = selectedDealer.token
            user_data?.dealers?.forEach((item: any) => {
              if (item.dealer_id == selectedDealer.value) {
                // TODO: Clean up this logic once backend migrates to CRDB (related to )
                if (Object.hasOwn(item?.contacts, 'delivery_emails')) {
                  user_data.dealer_email = item.contacts.delivery_emails.map(
                    (email) => email?.email
                  )
                } else {
                  user_data.dealer_email = item?.contacts?.lead_send
                }
                user_data.dealer_inbox = item.dealer_inbox
                user_data.dealer_region = item.country
              }
            })
          } else {
            const userToSet = resp.user
            userToSet.dealer_name = resp.user.dealer.name
            const dealer_select: DealerElement[] = []
            userData?.dealers?.forEach((item: any) => {
              dealer_select.push({
                icon: '',
                label: item.name,
                token: item.token,
                value: item.dealer_id,
              })
            })
            userToSet.dealer_select = dealer_select
            sessionStorage.setItem(
              'selectedDealer',
              JSON.stringify(userToSet.dealer_select[0])
            )
            setUserData(userToSet)
          }
          setUserData(user_data)
          setIsLoadingUserData(false)
        })
    }
    setIsLoadingUserData(false)
  }, [])

  const ProtectedRoute = ({ children }: any) => {
    if (!sessionStorage.getItem('token')) {
      return <Navigate to="/signin" replace />
    }

    return children
  }

  return (
    <BrowserRouter>
      {!isLoadingUserData ? (
        <UserContext.Provider value={userValues}>
          <Routes>
            <Route
              path="/"
              element={<Navigate to={'/' + Constants.ROUTE_SIGN_IN} />}
            />
            <Route path={'/' + Constants.ROUTE_SIGN_IN} element={<SignIn />} />
            <Route
              path={'/' + Constants.ROUTE_LEAD_DETAILS}
              element={<LeadDetails />}
            />
            <Route
              path={'/' + Constants.ROUTE_DEALER_RESOURCES}
              element={<DealerResources />}
            />
            <Route
              path={'/' + Constants.ROUTE_REDIRECT}
              element={<RedirectPage />}
            />
            <Route
              path={'/' + Constants.ROUTE_LEAD_LOG}
              element={
                <ProtectedRoute>
                  <LeadLog />
                </ProtectedRoute>
              }
            />
            <Route
              path={'/' + Constants.ROUTE_ACCOUNT_SETTINGS}
              element={
                <ProtectedRoute>
                  <AccountSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path={'/' + Constants.ROUTE_MANAGE_BILLING}
              element={
                <ProtectedRoute>
                  <ManageBilling />
                </ProtectedRoute>
              }
            />
            <Route
              path={'/' + Constants.ROUTE_MANAGE_USERS}
              element={
                <ProtectedRoute>
                  <ManageUsers />
                </ProtectedRoute>
              }
            />
          </Routes>
        </UserContext.Provider>
      ) : (
        <LoadingSpinner />
      )}
    </BrowserRouter>
  )
}

export default App
