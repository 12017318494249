import { logOut } from '../services/auth'

export const apiRequest = async (
  url: string,
  requestBodyInit?: RequestInit
) => {
  try {
    const response = await fetch(url, requestBodyInit)
    if (response.status === 401) {
      void logOut()
    }
    return response
  } catch (err: any) {
    console.error(err)
  }
}
