import type { ReactNode } from 'react'

import Dog from '../images/dog-sidebar.png'
import Logo from '../images/login-branding-sidebar.png'

interface DefaultLayoutProps {
  children: ReactNode
}

const AuthLayout = ({ children }: DefaultLayoutProps) => {
  return (
    <div id="wrapper">
      <div className="flex min-h-screen">
        <aside className="auth-sidebar hidden flex-col justify-between bg-sidebar-bk p-12 xl:flex xl:w-[512px]">
          <div>
            <div className="auth-logo mb-[5rem]">
              <img src={Logo} alt="Logo" />
            </div>
            <h1 className="text-5xl">
              Fetch better leads with Carbeeza UltraLead
            </h1>
          </div>
          <div className="auth-sidebar-dog p-8 ">
            <img src={Dog} alt="Logo" />
          </div>
        </aside>
        <div className="auth-content flex flex-1 items-center justify-center bg-[#F2F2F2] p-4">
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
