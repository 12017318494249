import { useContext, useMemo, useEffect, useState } from 'react'
import { TbFileDownload } from 'react-icons/tb'
import type { Column } from 'react-table'

import Dropdown from '../../components/Dropdown'
import Loading from '../../components/Loader'
import SuccessFailureModal from '../../components/SuccessFailureModal'
import Table from '../../components/Table'
import { UserContext } from '../../context/UserContext'
import DefaultLayout from '../../layout/DefaultLayout'
import LeadService from '../../services/leads'
interface LeadLogRecord {
  ultralead_id: string
  inquiry_date: string
  name: string
  source: string
  inventory_item: string
  state: string
  view_lead: string
}

const URL = import.meta.env.VITE_LEAD_DETAIL_API_URL

// BUG: Figure out why API call is using unset user context initially (resulting in 500 errors) before resolving correctly
const LeadLog = () => {
  const { userData } = useContext(UserContext)

  const [leads, setLeads] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [pageSize, setPageSize] = useState(25)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const filters = [
    {
      icon: '',
      label: 'Date',
      value: 'created_at',
    },
    {
      icon: '',
      label: 'Alphabetical [DSC]',
      value: 'first_name_desc',
    },
    {
      icon: '',
      label: 'Alphabetical [ASC]',
      value: 'first_name_asc',
    },
  ]

  const getLeadData = (
    filter?: string | null,
    limit?: number,
    page?: number
  ) => {
    const tableData: LeadLogRecord[] = []
    setIsLoading(true)

    LeadService.getUltraleads(
      userData.token,
      filter,
      sessionStorage.getItem('token')!,
      limit,
      page
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.data) {
          resp.data.forEach((item: any) => {
            tableData.push({
              inquiry_date: item.inquiry_date,
              inventory_item: item.inventory_item,
              name: item.name ? item.name : '-',
              source: item.source ? item.source : 'Unknown',
              state: item.state,
              ultralead_id: item.ultralead_id,
              view_lead: 'deww',
            })
          })
        }
        setTotalCount(resp.total_count)
        // Set Lead Data
        setLeads(tableData)

        // Finish Loading
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getLeadData()
  }, [])

  useEffect(() => {
    goToFirstPage()
  }, [pageSize])

  const columns: Column[] = useMemo(() => {
    return [
      {
        Header: 'UltraLead ID',
        accessor: 'ultralead_id',
      },
      {
        Header: 'Inquiry Date',
        accessor: 'inquiry_date',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Source',
        accessor: 'source',
      },
      {
        Header: 'Inventory Item',
        accessor: 'inventory_item',
      },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Cell: ViewLead,
        Header: 'View Lead',
        accessor: 'view_lead',
      },
    ]
  }, [])
  const handleFilterChange = (filter: any) => {
    setFilter(filter.value)
    getLeadData(filter.value, pageSize, pageNumber)
  }
  const changeResponseSize = (size: number) => {
    getLeadData(filter, size, pageNumber)
    setPageSize(size)
  }
  const nextPageNumber = () => {
    const nextPage = pageNumber + 1
    getLeadData(filter, pageSize, nextPage)
    setPageNumber(nextPage)
  }
  const previousPageNumber = () => {
    const previousPage = pageNumber - 1
    getLeadData(filter, pageSize, previousPage)
    setPageNumber(previousPage)
  }
  const goToFirstPage = () => {
    getLeadData(filter, pageSize, 1)
    setPageNumber(1)
  }
  const goToLastPage = () => {
    const LastPageNumber = Math.ceil(totalCount / pageSize)
    getLeadData(filter, pageSize, LastPageNumber)
    setPageNumber(LastPageNumber)
  }
  return (
    <DefaultLayout changeDealerAction={getLeadData}>
      <div className="lead-log-page h-full w-full">
        {/* {isLoading ? <Loading /> : ''} */}
        <h2 className="mb-4 text-4xl">Lead Log</h2>
        <div className="log-table-filter-wrapper">
          <div className="w-60">
            <label className="text-paragraph">Order by:</label>
            <Dropdown
              options={filters}
              defaultTitle="Select Filter"
              collapseClassName="w-full text-paragraph px-3 py-2 rounded inline-flex items-center cursor-pointer transition duration-300 ease-in-out border-2 border-paragraph/60"
              onChangeOption={handleFilterChange}
            />
          </div>
        </div>
        <div className="log-table-wrapper mt-5">
          <Table
            isLoading={isLoading}
            totalCount={totalCount}
            data={leads}
            columns={columns}
            pageNumber={pageNumber}
            changeResponseSize={changeResponseSize}
            nextPageNumber={nextPageNumber}
            previousPageNumber={previousPageNumber}
            goToFirstPage={goToFirstPage}
            goToLastPage={goToLastPage}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export const ViewLead = (props: any) => {
  const [showDownloading, setShowDownloading] = useState(false)
  const [showDownloadComplete, setShowDownloadComplete] = useState(false)
  const [showError, setShowError] = useState(false)
  const downloadLead = async (uid: string, type: number) => {
    setShowDownloading(true)
    try {
      const response = await fetch(
        `${URL}/api/ultralead/${uid}/export?type=${type}`
      )
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = `adf.xml`
        a.click()
      })
      setShowDownloading(false)
      setShowDownloadComplete(true)
      return response
    } catch (error) {
      setShowDownloading(false)
      setShowError(true)
      return error
    }
  }

  let uid = props.row.cells

  if (uid.length > 0) {
    uid = uid[0].value
  }

  return (
    <div className="flex">
      <span onClick={() => downloadLead(uid, 1)}>
        <button className="mr-5 flex items-center justify-center text-base font-light text-paragraph underline underline-offset-4">
          <TbFileDownload className="mr-1 h-4 w-4" />
          Original
        </button>
      </span>
      <span onClick={() => downloadLead(uid, 2)}>
        <button className="flex items-center justify-center text-base font-light text-paragraph underline underline-offset-4">
          <TbFileDownload className="mr-1 h-4 w-4" />
          Ultralead
        </button>
      </span>
      {showDownloading ||
        (showDownloadComplete && (
          <SuccessFailureModal
            title={'Download Lead'}
            message={{
              __html: `<div>${
                showDownloading ? 'Downloading' : 'Download Complete.'
              }</div>`,
            }}
            buttonText={'Done'}
            buttonAction={() => {
              setShowDownloadComplete(false)
              setShowDownloading(false)
              setShowError(false)
            }}
            onClose={() => {
              setShowDownloadComplete(false)
              setShowDownloading(false)
              setShowError(false)
            }}
          />
        ))}
      {showDownloading ||
        (showDownloadComplete && (
          <SuccessFailureModal
            title={'Download Lead'}
            message={{
              __html: `<div>${
                showDownloading ? 'Downloading' : 'Download Complete.'
              }</div>`,
            }}
            buttonText={'Done'}
            buttonAction={() => {
              setShowDownloadComplete(false)
              setShowDownloading(false)
              setShowError(false)
            }}
            onClose={() => {
              setShowDownloadComplete(false)
              setShowDownloading(false)
              setShowError(false)
            }}
          />
        ))}
      {showError && (
        <SuccessFailureModal
          title="Hmm…"
          message={{
            __html:
              '<div>Something went wrong. Please try again. If the problem persists, please <a href="mailto:support@carbeeza.com" class="text-[#9475FF]">contact support.</a></div>',
          }}
          buttonText="Close"
          buttonAction={() => {
            setShowDownloadComplete(false)
            setShowDownloading(false)
            setShowError(false)
          }}
          onClose={() => {
            setShowDownloadComplete(false)
            setShowDownloading(false)
            setShowError(false)
          }}
        />
      )}
    </div>
  )
}

export default LeadLog
