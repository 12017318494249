import { useState, useEffect, useContext } from 'react'
import {
  HiOutlineEye,
  HiOutlineEyeSlash,
  HiOutlineCheckCircle,
} from 'react-icons/hi2'

import ErrorAlert from '../../components/ErrorAlert'
import Modal from '../../components/Modal'
import { UserContext } from '../../context/UserContext'
import UsersService from '../../services/users'

interface Props {
  password: string
  setPassword: (password: string) => void
  isOpenModel?: boolean
  toggleSuccessModal: () => void
  toggleErrorModal: () => void
}

const Password = ({
  password,
  setPassword,
  isOpenModel = false,
  toggleSuccessModal,
  toggleErrorModal,
}: Props) => {
  const { userData } = useContext(UserContext)

  const [isOpen, setIsOpen] = useState(isOpenModel)

  const [oldPassword, setOldPassword] = useState(password)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if (showError) {
      setShowError(false)
      setErrorMsg('')
    }
    if (newPassword === '' || confirmPassword === '') {
      setSubmitDisabled(true)
    } else if (newPassword.length < 6 || confirmPassword.length < 6) {
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
  }, [newPassword, confirmPassword])

  const toggleShowNewPassword = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation()
    setShowNewPassword(!showNewPassword)
  }

  const toggleShowConfirmPassword = (
    event: React.MouseEvent<HTMLSpanElement>
  ) => {
    event.stopPropagation()
    setShowConfirmPassword(!showConfirmPassword)
  }

  const onChangeoldPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value)
  }

  const onChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value)
  }

  const onChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
  }

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsOpen(true)
  }

  const reset = () => {
    setNewPassword('')
    setConfirmPassword('')
    setErrorMsg('')
    setShowNewPassword(false)
    setShowConfirmPassword(false)
    setSubmitDisabled(false)
    setShowError(false)
  }

  const handleCloseModal = () => {
    reset()
    setIsOpen(false)
  }

  const handleSave = () => {
    if (newPassword === confirmPassword) {
      if (newPassword.length < 6) {
        setShowError(true)
        setErrorMsg('New password must contain at least 6 characters')
      }
      let data = new FormData()

      // Update Password State
      setNewPassword(newPassword)
      setPassword(newPassword)

      // Add Form Props
      data.append('password', newPassword)

      UsersService.updateUserPassword(
        userData.uid,
        data,
        userData.token,
        sessionStorage.getItem('token')!
      )
        .then((resp) => resp.json())
        .then(() => {
          // Close Modal
          handleCloseModal()
          setOldPassword(newPassword)
          toggleSuccessModal()
        })
        .catch(() => {
          toggleErrorModal()
        })
    } else {
      setShowError(true)
      setErrorMsg('The password you have entered do not match')
    }
  }

  return (
    <>
      {!isOpenModel ? <p className="setting-name">Password</p> : ''}
      <div className="flex items-center justify-between py-4 md:justify-center">
        <p className="setting-content mr-8">{'*'.repeat(password.length)}</p>
        <button
          className="border-b border-boundary-2 text-lg leading-tight text-paragraph"
          onClick={handleOpenModal}
        >
          {!isOpenModel ? 'Edit' : ''}
        </button>

        <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <h3 className="text-gray-900 mb-8 text-3xl font-medium leading-6">
            Update your Password
          </h3>
          {isOpenModel ? (
            <div className="mb-8 text-[20px] text-[#3B2F66]">
              Welcome to UltraLead! To get started, please create a new password
              for your account.
            </div>
          ) : (
            <div className="mb-8 text-[20px] text-[##3B2F66]">
              Please Note: Passwords must contain at least 6 characters
            </div>
          )}
          <div className="mb-6">
            {errorMsg !== '' && <ErrorAlert msg={errorMsg} />}
            {/* TODO: Unhide/update old password functionality once backend adds capability to handle it */}
            {/* <div className="mb-6">
              {!isOpenModel ? (
                <label
                  htmlFor="input-old-password"
                  className="mb-1 block text-left text-paragraph"
                >
                  Old Password
                </label>
              ) : (
                ''
              )}
              {!isOpenModel ? (
                <input
                  id="input-old-password"
                  type="password"
                  placeholder="Enter where ultralead sends email to"
                  value={oldPassword}
                  className="w-full rounded bg-transparent px-5 py-2 outline-none transition disabled:cursor-default"
                  disabled
                  onChange={onChangeoldPassword}
                />
              ) : (
                ''
              )}
            </div> */}
            <div
              className={`relative mb-6 ${
                showError
                  ? 'wrong-input'
                  : newPassword.length > 5
                  ? 'success-input'
                  : ''
              }`}
            >
              <label
                htmlFor="input-new-password"
                className="mb-1 block text-left text-paragraph"
              >
                New Password{' '}
                {isOpenModel &&
                  '(Passwords must contain at least 6 characters.)'}
              </label>
              <input
                id="input-new-password"
                type={showNewPassword ? 'text' : 'password'}
                placeholder="Enter new password"
                value={newPassword}
                className={`w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default ${
                  confirmPassword.length > 5 && newPassword === confirmPassword
                    ? 'border-[1px] border-[#A4C18] bg-[#F2F7EF]'
                    : 'bg-transparent  outline-none'
                }`}
                onChange={onChangeNewPassword}
              />
              <button
                className="absolute bottom-3 right-4 cursor-pointer disabled:cursor-not-allowed"
                onClick={toggleShowNewPassword}
                disabled={newPassword ? false : true}
              >
                {showNewPassword ? (
                  <HiOutlineEyeSlash className="h-5 w-5" />
                ) : confirmPassword.length > 5 &&
                  newPassword === confirmPassword ? (
                  <HiOutlineCheckCircle />
                ) : (
                  <HiOutlineEye className="h-5 w-5" />
                )}
              </button>
            </div>
            <div
              className={`relative ${
                showError
                  ? 'wrong-input'
                  : confirmPassword.length > 5 &&
                    newPassword === confirmPassword
                  ? 'success-input'
                  : ''
              }`}
            >
              <label
                htmlFor="input-confirm-password"
                className="mb-1 block text-left text-paragraph"
              >
                Confirm {!isOpenModel && 'New '}Password
              </label>
              <input
                id="input-confirm-password"
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm new password"
                value={confirmPassword}
                className="w-full rounded bg-transparent px-5 py-2 outline-none transition disabled:cursor-default"
                onChange={onChangeConfirmPassword}
              />
              <button
                className="absolute bottom-3 right-4 cursor-pointer disabled:cursor-not-allowed"
                onClick={toggleShowConfirmPassword}
                disabled={confirmPassword ? false : true}
              >
                {showConfirmPassword ? (
                  <HiOutlineEyeSlash className="h-5 w-5" />
                ) : confirmPassword.length > 5 &&
                  newPassword === confirmPassword ? (
                  <HiOutlineCheckCircle />
                ) : (
                  <HiOutlineEye className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>
          {isOpenModel ? (
            ''
          ) : (
            <div className="mb-8 text-[20px] text-[#3B2F66]">
              If you ever forget your password our{' '}
              <a
                className="cursor-pinter text-[#9475FF]"
                href="https://support.ultralead.ai/support/home"
                target="_blank"
              >
                support team
              </a>{' '}
              can help you recover your account.
            </div>
          )}
          <div className="modal-button-group flex justify-end gap-6">
            <button
              type="button"
              className="btn-secondary flex w-32 items-center justify-center rounded-lg py-2"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-primary flex w-32 items-center justify-center rounded-lg py-2"
              disabled={showError || submitDisabled}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Password
