import { apiRequest } from '../utils/apiRequest.utils'

const URL = import.meta.env.VITE_LEAD_DETAIL_API_URL

/**
 * [getUserAccount - Get User Account]
 */
const getUserAccount = (uid: string, firebase_token: string) => {
  return apiRequest(`${URL}/api/account/${uid}`, {
    headers: {
      Authorization: `Bearer ${firebase_token}`,
    },
  })
}

/**
 * [getUsers - Get User List]
 */
const getUsers = (
  token: string,
  firebase_token: string,
  limit?: number,
  page?: number
) => {
  let orderBy = ''
  let order = orderBy.includes('_asc') ? 'asc' : 'desc'

  orderBy = orderBy.replace('_asc', '')
  orderBy = orderBy.replace('_desc', '')

  return apiRequest(
    `${URL}/api/users?token=${token}&order=${order}&order_by=${orderBy}${
      limit ? '&limit=' + limit : ''
    }${page ? '&page=' + page : ''}`,
    {
      headers: {
        Authorization: `Bearer ${firebase_token}`,
      },
    }
  )
}

/**
 * [createUser - Create new user]
 */
const createUser = (data: any, token: string, firebase_token: string) => {
  return apiRequest(`${URL}/api/user/create?token=${token}`, {
    body: data,
    headers: {
      Authorization: `Bearer ${firebase_token}`,
    },
    method: 'POST',
  })
}

/**
 * [updateUserEmail - Update User Email]
 */
const updateUserEmail = (
  uid: string,
  data: any,
  token: string,
  firebase_token: string
) => {
  return apiRequest(`${URL}/api/user/${uid}/email?token=${token}`, {
    body: data,
    headers: {
      Authorization: `Bearer ${firebase_token}`,
    },
    method: 'POST',
  })
}

/**
 * [updateUserName - Update User Name]
 */
const updateUserName = (
  uid: string,
  data: any,
  token: string,
  firebase_token: string
) => {
  return apiRequest(`${URL}/api/user/${uid}/name?token=${token}`, {
    body: data,
    headers: {
      Authorization: `Bearer ${firebase_token}`,
    },
    method: 'POST',
  })
}

/**
 * [updateUserPassword - Update User Name]
 */
const updateUserPassword = (
  uid: string,
  data: any,
  token: string,
  firebase_token: string
) => {
  return apiRequest(`${URL}/api/user/${uid}/password?token=${token}`, {
    body: data,
    headers: {
      Authorization: `Bearer ${firebase_token}`,
    },
    method: 'POST',
  })
}

/**
 * [updateUserPassword - Update User Name]
 */
const updateSendEmail = (
  uid: string,
  data: any,
  token: string,
  firebase_token: string
) => {
  return apiRequest(`${URL}/api/dealer/${uid}/email?token=${token}`, {
    body: data,
    headers: {
      Authorization: `Bearer ${firebase_token}`,
    },
    method: 'POST',
  })
}

export default {
  createUser,
  getUserAccount,
  getUsers,
  updateSendEmail,
  updateUserEmail,
  updateUserName,
  updateUserPassword,
}
