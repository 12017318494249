import { useState, useContext } from 'react'

import Modal from '../../components/Modal'
import { UserContext } from '../../context/UserContext'
import UsersService from '../../services/users'
import { validateEmail } from '../../utils/email.utils'

interface UserFields {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}
interface UserErrors {
  firstName: boolean
  lastName: boolean
  email: boolean
  password: boolean
  confirmPassword: boolean
}

interface UserProps {
  onClose: () => void
  toggleSuccessModal: () => void
  toggleErrorModal: () => void
}

const User = ({ onClose, toggleSuccessModal, toggleErrorModal }: UserProps) => {
  const { userData } = useContext(UserContext)
  const [errorMessage, setErrorMessage] = useState('')
  const [user, setUser] = useState<UserFields>({
    confirmPassword: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  })
  const [userErrors, setUserErrors] = useState<UserErrors>({
    confirmPassword: false,
    email: false,
    firstName: false,
    lastName: false,
    password: false,
  })
  const [admin, setAdmin] = useState('0')

  const [submitDisabled, setSubmitDisabled] = useState(false)

  const onChangeUser = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setUser((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }))
    setUserErrors((prevState) => ({
      ...prevState,
      [fieldName]: false,
    }))
    setSubmitDisabled(false)
    setErrorMessage('')
  }

  const onChangeAdmin = (e: any): void => {
    setAdmin(e.target.value)
  }

  const isFormValid = (): boolean => {
    let formValid = true
    const userObjKeys = Object.keys(user) as Array<keyof UserFields>
    userObjKeys.forEach((key) => {
      let isValid = true
      isValid = user[key]?.trim()?.length !== 0
      if (key === 'email') {
        isValid = validateEmail(user.email)
      }
      if (key === 'confirmPassword') {
        isValid = user.confirmPassword === user.password
      }
      if (!isValid) {
        formValid = false
      }
      setUserErrors((prevState) => ({
        ...prevState,
        [key]: !isValid,
      }))
    })
    return formValid
  }

  const handleSave = () => {
    let data = new FormData()
    setSubmitDisabled(true)
    // Validation
    if (!isFormValid()) {
      setSubmitDisabled(false)
      return
    }

    // Add Form Props
    data.append('first_name', user.firstName)
    data.append('last_name', user.lastName)
    data.append('email', user.email)
    data.append('password', user.password)
    data.append('confirm_password', user.confirmPassword)
    data.append('admin', admin)

    if (admin != '1') {
      data.append('dealer', userData.dealer)
    }

    UsersService.createUser(
      data,
      userData.token,
      sessionStorage.getItem('token')!
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status === 'error') {
          handleErrorMessages(resp.message)
          return
        }
        // Close Modal
        setSubmitDisabled(false)
        onClose()
        toggleSuccessModal()
      })
      .catch(() => {
        setSubmitDisabled(false)
        toggleErrorModal()
      })
  }

  const handleErrorMessages = (message: string) => {
    if (message === 'User already exist.') {
      setErrorMessage('This email address already exists.')
      setUserErrors((prevState) => ({
        ...prevState,
        email: true,
      }))
    }
  }

  //General input styles
  const getInputStyles = (errorField: boolean) => {
    return `w-full rounded px-5 py-2 transition disabled:cursor-default 
                ${
                  errorField
                    ? 'border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error'
                    : 'bg-transparent outline-none'
                }`
  }

  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <h3 className="text-gray-900 mb-8 text-3xl font-medium leading-6">
          Create A User
        </h3>
        <form>
          <div className="group mb-16">
            <div className="half mb-6">
              <label
                htmlFor="first-name"
                className="mb-1 block text-left text-paragraph"
              >
                First Name
              </label>
              <input
                id="first-name"
                type="text"
                name="firstName"
                placeholder="Enter first name"
                value={user.firstName}
                className={getInputStyles(userErrors.firstName)}
                onChange={onChangeUser}
              />
            </div>
            <div className={`half mb-6`}>
              <label
                htmlFor="last-name"
                className="mb-1 block text-left text-paragraph"
              >
                Last Name
              </label>
              <input
                id="last-name"
                name="lastName"
                type="text"
                placeholder="Enter last name"
                value={user.lastName}
                className={getInputStyles(userErrors.lastName)}
                onChange={onChangeUser}
              />
            </div>
          </div>
          <div className="group mb-16">
            <div className={`mb-6`}>
              <label
                htmlFor="email"
                className="mb-1 block text-left text-paragraph"
              >
                Email
              </label>
              <input
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                placeholder="Enter email address"
                value={user.email}
                className={getInputStyles(userErrors.email)}
                onChange={onChangeUser}
              />
              {errorMessage !== '' && (
                <div className="text-sm text-red-700">{errorMessage}</div>
              )}
            </div>
            <div>
              <div className="half">
                <label
                  htmlFor="password"
                  className="mb-1 block text-left text-paragraph"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="Password"
                  value={user.password}
                  className={getInputStyles(userErrors.password)}
                  onChange={onChangeUser}
                />
              </div>
              <div className={`half mb-6`}>
                <label
                  htmlFor="password-confirm"
                  className="mb-1 block text-left text-paragraph"
                >
                  Confirm Password
                </label>
                <input
                  id="confirm-password"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={user.confirmPassword}
                  className={getInputStyles(userErrors.confirmPassword)}
                  onChange={onChangeUser}
                />
              </div>
            </div>
          </div>
          <div className="group mb-16">
            <div className={`half mb-6`}>
              <label
                htmlFor="input-admin"
                className="mb-1 block text-left text-paragraph"
              >
                Admin
              </label>
              <select
                placeholder="Admin"
                value={admin}
                className="w-full rounded bg-transparent px-5 py-2 outline-none transition disabled:cursor-default"
                onChange={onChangeAdmin}
              >
                <option value="0">NO</option>
                <option value="1">YES</option>
              </select>
            </div>
          </div>
        </form>
        <div className="modal-button-group flex justify-end gap-6">
          <button
            type="button"
            className="btn-secondary flex w-32 items-center justify-center rounded-lg py-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn-primary flex w-32 items-center justify-center rounded-lg py-2"
            disabled={submitDisabled}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  )
}

export default User
