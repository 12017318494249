export const validateEmail = (email: string) => {
  const emails = email.replace(/\s/g, '').split(',')
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  for (let i = 0; i < emails.length; i++) {
    const validate = regex.test(String(emails[i]).toLowerCase())
    if (!validate) {
      return validate
    }
  }
  return true
}
