import type { ReactNode } from 'react'
import { useState } from 'react'

import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'
import MobileDisclaimer from '../pages/MobileDisclaimer'

interface DefaultLayoutProps {
  changeDealerAction: () => void
  children: ReactNode
}

const DefaultLayout = ({
  changeDealerAction,
  children,
}: DefaultLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  //Default action
  const defaultDealerAction = () => {
    return
  }
  if (!changeDealerAction) {
    changeDealerAction = defaultDealerAction
  }

  return (
    <div id="wrapper">
      <Header
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        changeDealerAction={changeDealerAction}
      />
      <MobileDisclaimer />
      <div className="main-content relative hidden bg-sidebar-bk md:flex">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex w-full flex-1 bg-white pb-24 pl-8 pr-4 pt-14 md:pl-10 md:pr-6 2xl:pl-20 2xl:pr-11">
          {children}
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default DefaultLayout
