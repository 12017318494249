import Dog from '../../images/dog-disclaimer.svg'

const MobileDisclaimer = () => {
  return (
    <div id="wrapper" className="md:hidden">
      <div className="min-h-[calc(100vh-300px)] bg-[#F8F5F3] py-[58px]">
        <img src={Dog} alt="dog" className="m-auto mb-[58px]" />
        <p className="m-auto max-w-[290px] text-center font-CircularStd text-[20px] leading-[26px] text-[#002257]">
          Hey there! Just a quick heads up -{' '}
          <b className="font-bold">
            Ultralead is currently tailored for larger screens only
          </b>{' '}
          – so we recommend using a desktop device to access and get the most
          out of it.
          <br />
          <br />
          Thank you for your understanding, and we look forward to having you
          with us.
        </p>
      </div>
    </div>
  )
}

export default MobileDisclaimer
