const ManageBillingIcon = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.9 0H11.1L11.8586 3.16078C11.9488 3.33633 12 3.53694 12 3.75C12 4.44036 11.4627 5 10.8 5C10.1373 5 9.6 4.44036 9.6 3.75C9.6 4.44036 9.06274 5 8.4 5C7.73726 5 7.2 4.44036 7.2 3.75C7.2 4.44036 6.66274 5 6 5C5.33726 5 4.8 4.44036 4.8 3.75C4.8 4.44036 4.26274 5 3.6 5C2.93726 5 2.4 4.44036 2.4 3.75C2.4 4.44036 1.86274 5 1.2 5C0.537258 5 0 4.44036 0 3.75C0 3.53694 0.0511719 3.33633 0.141412 3.16078L0.9 0ZM1.2 6.25H2.4V8.125H9.6V6.25H10.8V10H1.2V6.25Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ManageBillingIcon
