import { useContext, useEffect, useRef } from 'react'
import { PiCurrencyDollarSimpleBold, PiBookOpenBold } from 'react-icons/pi'
import { NavLink, useLocation } from 'react-router-dom'

import * as Constants from '../constants/constants'
import { UserContext } from '../context/UserContext'
import AccountSettingsIcon from '../images/icons/AccountSettingsIcon'
import ManageBillingIcon from '../images/icons/ManageBillingIcon'

interface SidebarProps {
  sidebarOpen: boolean
  setSidebarOpen: (arg: boolean) => void
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const { userData } = useContext(UserContext)

  let sidebarMenuItems = [
    {
      caption: 'Lead Log',
      icon: <PiCurrencyDollarSimpleBold />,
      slug: Constants.ROUTE_LEAD_LOG,
    },
    {
      caption: 'Account Settings',
      icon: <AccountSettingsIcon />,
      slug: Constants.ROUTE_ACCOUNT_SETTINGS,
    },
    {
      caption: 'Manage Billing',
      icon: <ManageBillingIcon />,
      slug: Constants.ROUTE_MANAGE_BILLING,
    },
    {
      caption: 'Dealer Resources',
      icon: <PiBookOpenBold />,
      slug: Constants.ROUTE_DEALER_RESOURCES,
    },
  ]

  if (userData.admin == 1) {
    sidebarMenuItems.push({
      caption: 'Manage Users',
      icon: <ManageBillingIcon />,
      slug: Constants.ROUTE_MANAGE_USERS,
    })
  }
  const location = useLocation()
  const { pathname } = location

  const sidebar = useRef<any>(null)

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current) return
      if (!sidebarOpen || sidebar.current.contains(target)) return
      setSidebarOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return
      setSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  let redirect = 'https://billing.stripe.com/p/login/4gw5nhe3e7sd4hi3cc'

  if (userData.dealer_region !== 'USA') {
    redirect = 'https://billing.stripe.com/p/login/dR64h1bXo0f88Rq4gg'
  }

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-30 flex h-full w-[360px] flex-col bg-sidebar-bk duration-300 ease-linear lg:static lg:z-10 lg:translate-x-0 ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className="no-scrollbar sticky top-20 flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="px-3 py-14">
          <ul className="nav-menu flex flex-col border-b">
            {sidebarMenuItems.map((item) => {
              return (
                <li key={item.slug}>
                  <NavLink
                    to={
                      item.slug !== Constants.ROUTE_MANAGE_BILLING
                        ? `/${item.slug}`
                        : redirect
                    }
                    className={`nav-menu-item ${
                      pathname.includes(item.slug) && 'active'
                    } group relative flex items-center rounded-sm border-t border-t px-7 py-[6px] text-text duration-300 ease-in-out`}
                  >
                    <div
                      className={`flex w-full items-center rounded-sm px-2 py-1 hover:bg-[#DED7F4] ${
                        pathname.includes(item.slug) &&
                        'bg-[#DED7F4] font-medium'
                      }`}
                    >
                      <div
                        className={`menu-icon-wrapper item mr-2 h-6 w-6 items-center justify-center rounded-full p-1 ${
                          pathname.includes(item.slug)
                            ? 'bg-[#3B2F66]'
                            : 'bg-[#D6CAF4]'
                        }`}
                      >
                        {item.icon}
                      </div>
                      {item.caption}
                    </div>
                  </NavLink>
                </li>
              )
            })}
          </ul>
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  )
}

export default Sidebar
