import type { User } from 'firebase/auth'
import { useState, useEffect, useContext } from 'react'
import { HiOutlineEye, HiOutlineEyeSlash } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'

import SuccessFailureModal from '../../components/SuccessFailureModal'
import * as Constants from '../../constants/constants'
import { UserContext } from '../../context/UserContext'
import Logo from '../../images/login-branding.png'
import AuthLayout from '../../layout/AuthLayout'
import { logIn } from '../../services/auth'
import UsersService from '../../services/users'
import Password from '../AccountSettings/Password'

const SignIn = () => {
  const { setUserData } = useContext(UserContext)
  const navigate = useNavigate()
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isResetPassword, setResetPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const onChangeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser(e.target.value)
  }

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const afterLogin = (authUser: User) => {
    UsersService.getUserAccount(authUser.uid, authUser.accessToken)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status == 'success') {
          sessionStorage.setItem('user', JSON.stringify({ uid: authUser.uid }))
          const userToSet = resp.user
          userToSet.dealer_name = resp.user.dealer.name
          const dealer_select = []
          userToSet?.dealers?.forEach((item: any) => {
            dealer_select.push({
              icon: '',
              label: item.name,
              token: item.token,
              value: item.dealer_id,
            })
          })
          userToSet.dealer_select = dealer_select
          userToSet.uid = authUser.uid
          setUserData(userToSet)
          sessionStorage.setItem(
            'selectedDealer',
            JSON.stringify(userToSet.dealer_select[0])
          )
          if (resp.user.reset === true) {
            setPassword('')
            setResetPassword(true)
          } else {
            navigate('/' + Constants.ROUTE_LEAD_LOG)
          }
        }
        setSubmitDisabled(false)
      })
      .catch(() => {
        setShowError(true)
      })
  }

  const handleLogin = () => {
    setSubmitDisabled(true)
    void logIn(user, password, afterLogin)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleLogin()
    }
  }

  useEffect(() => {
    if (showError) {
      setShowError(false)
    }
    if (user === '' || password === '') {
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
  }, [user, password])

  return (
    <AuthLayout>
      <div
        className="flex w-full max-w-[700px] flex-col items-center justify-center text-center"
        onKeyDown={handleKeyDown}
      >
        <div className="signin-dialog mb-16 w-full rounded-3xl border border-[#EBE6E3] bg-white px-8 py-8 lg:px-24 lg:py-16">
          <div className="dialog-logo mb-12 flex justify-center">
            <img src={Logo} alt="Logo" />
          </div>

          <div className={`signgin-form ${showError && 'wrong-input'}`}>
            <h2 className="mb-4">Sign in:</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
            >
              <div className="mb-4">
                <label
                  htmlFor="signin-input-user"
                  className="mb-[6px] block text-left text-paragraph"
                >
                  Username or Email address
                </label>
                <input
                  id="signin-input-user"
                  type="text"
                  placeholder="Enter username or email address"
                  value={user}
                  className="w-full rounded bg-transparent px-5 py-2 outline-none transition disabled:cursor-default"
                  onChange={onChangeUser}
                />
              </div>

              <div className="relative mb-8">
                <div className="mb-[6px] flex justify-between">
                  <label
                    htmlFor="signin-input-password"
                    className="block text-paragraph"
                  >
                    Password
                  </label>
                </div>
                <input
                  id="signin-input-password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter password"
                  value={password}
                  className="border-stroke w-full rounded border-[1.5px] bg-transparent py-2 pl-5 pr-11 outline-none transition disabled:cursor-default"
                  onChange={onChangePassword}
                  autoComplete="on"
                />
                <button
                  className="absolute bottom-3 right-4 cursor-pointer disabled:cursor-not-allowed"
                  onClick={toggleShowPassword}
                  disabled={password ? false : true}
                >
                  {showPassword ? (
                    <HiOutlineEyeSlash className="h-5 w-5" />
                  ) : (
                    <HiOutlineEye className="h-5 w-5" />
                  )}
                </button>
              </div>
            </form>
            <div>
              <button
                type="submit"
                className="btn-primary flex w-full justify-center rounded-lg py-3"
                disabled={showError || submitDisabled}
                onClick={handleLogin}
              >
                Continue
              </button>
            </div>
          </div>
        </div>

        {isResetPassword ? (
          <Password
            password=""
            setPassword={setPassword}
            isOpenModel={true}
            toggleSuccessModal={() => setShowSuccessModal(true)}
            toggleErrorModal={() => setShowError(true)}
          />
        ) : (
          ''
        )}
        {showSuccessModal ? (
          <SuccessFailureModal
            title="Success! You’re all set."
            message={{
              __html:
                "<div>Congratulations! Your password has been successfully created. Click the 'Sign In' button to access your account.</div>",
            }}
            buttonText="Sign In"
            buttonAction={() => handleLogin()}
            onClose={() => {
              setShowSuccessModal(false)
            }}
          />
        ) : (
          ''
        )}
        <div className="signgin-footer border-gray-gradient w-full border-t px-8 pt-8 lg:px-24">
          <h3 className="mb-[10px]">Can't log in?</h3>
          <div className="mb-16">
            <a
              href="mailto:support@carbeeza.com"
              className="border-b border-boundary-2 pb-[4px] text-sm text-primary"
            >
              Contact us for help
            </a>
          </div>
          <p className="text-paragraph">
            © 2024 Carbeeza Inc
            <br />
            All rights reserved
          </p>
        </div>
      </div>
    </AuthLayout>
  )
}

export default SignIn
