import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AvatarDropdown from '../components/AvatarDropdown'
import type { DropdownOption } from '../components/Dropdown'
import Dropdown from '../components/Dropdown'
import { UserContext } from '../context/UserContext'
import Logo from '../images/header-branding.png'
import { logOut } from '../services/auth'

const Header = (props: {
  sidebarOpen: string | boolean | undefined
  setSidebarOpen: (arg0: boolean) => void
  changeDealerAction: () => void
}) => {
  const { userData, setUserData } = useContext(UserContext)
  const navigate = useNavigate()
  const [dealer, setDealer] = useState<DropdownOption>({
    icon: '',
    label: userData?.dealer_name,
    token: userData?.token,
    value: userData?.dealer,
  })
  const [dealerNames, setDealerNames] = useState(userData?.dealer_select)
  const [dealerName, setDealerName] = useState(userData?.dealer_name)
  const avatarDropdownOptions = [
    {
      label: 'Log out',
      slug: '/',
      value: 'log_out',
    },
  ]

  const toggleSidebar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    props.setSidebarOpen(!props.sidebarOpen)
  }

  useEffect(() => {
    const dealer_select = []
    userData?.dealers?.forEach((item: any) => {
      dealer_select.push({
        icon: '',
        label: item.name,
        token: item.token,
        value: item.dealer_id,
      })
    })
    setDealerNames(dealer_select)
    setDealerName(userData?.dealer_name)
  }, [userData])

  const handleDealerChange = (dealer: DropdownOption) => {
    setDealer(dealer)
    const user_data = userData

    user_data.dealer = dealer.value
    user_data.dealer_name = dealer.label
    user_data.token = dealer.token

    user_data?.dealers?.forEach((item: any) => {
      if (item.dealer_id == dealer.value) {
        // TODO: Clean up this logic once backend migrates to CRDB
        if (Object.hasOwn(item?.contacts, 'delivery_emails')) {
          user_data.dealer_email = item.contacts.delivery_emails.map(
            (email) => email?.email
          )
        } else {
          user_data.dealer_email = item?.contacts?.lead_send
        }
        user_data.dealer_inbox = item.dealer_inbox
        user_data.dealer_region = item.country
      }
    })
    setUserData({ ...user_data })
    setDealerName(dealer.label)
    sessionStorage.setItem('selectedDealer', JSON.stringify(dealer))
    props.changeDealerAction()
  }

  const handleAvatarChangeOption = () => {
    void logOut()
  }
  const reloadPage = () => {
    window.location.reload()
  }
  return (
    <header className="header sticky top-0 z-50 flex h-[5rem] items-center">
      <div className="flex flex-grow items-center justify-between px-4 px-6 2xl:pl-12">
        <div className="flex items-center">
          <button
            aria-controls="sidebar"
            onClick={toggleSidebar}
            className="bg-paragraph/900 block rounded-sm border border-gray p-1 shadow-sm lg:hidden"
          >
            <span className="relative block h-5 w-5 cursor-pointer">
              <span className="du-block absolute right-0 h-full w-full">
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-sidebar-bk delay-[0] duration-200 ease-in-out ${
                    !props.sidebarOpen && '!w-full delay-300'
                  }`}
                ></span>
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-sidebar-bk delay-150 duration-200 ease-in-out ${
                    !props.sidebarOpen && 'delay-400 !w-full'
                  }`}
                ></span>
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-sidebar-bk delay-200 duration-200 ease-in-out ${
                    !props.sidebarOpen && '!w-full delay-500'
                  }`}
                ></span>
              </span>
              <span className="absolute right-0 h-full w-full rotate-45">
                <span
                  className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-sidebar-bk delay-300 duration-200 ease-in-out ${
                    !props.sidebarOpen && '!h-0 !delay-[0]'
                  }`}
                ></span>
                <span
                  className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-sidebar-bk duration-200 ease-in-out ${
                    !props.sidebarOpen && '!h-0 !delay-200'
                  }`}
                ></span>
              </span>
            </span>
          </button>
          <div
            className="logo hidden cursor-pointer md:block"
            onClick={() => reloadPage()}
          >
            <img src={Logo} alt="Logo" />
          </div>
        </div>

        <div className="hidden md:flex">
          <div className="dealer-dropdown mr-2 w-[240px] md:mr-4 2xl:mr-8">
            <Dropdown
              options={dealerNames}
              defaultTitle={dealerName}
              collapseClassName="w-full header-dropdown-collapsed text-paragraph px-6 pr-[35px] py-[10px] rounded-3xl inline-flex items-center cursor-pointer transition duration-300 ease-in-out border-2 border-paragraph/60"
              onChangeOption={handleDealerChange}
            />
          </div>
          <div className="avatar-dropdown mr-2">
            <AvatarDropdown
              options={avatarDropdownOptions}
              defaultTitle={dealer?.icon}
              defaultName={dealer?.label}
              collapseClassName="w-full text-paragraph rounded-3xl inline-flex items-center cursor-pointer transition duration-300 ease-in-out border-2 border-paragraph/60"
              onChangeOption={handleAvatarChangeOption}
            />
          </div>
        </div>
        <div
          className="logo cursor-pointer md:hidden"
          onClick={() => reloadPage()}
        >
          <img src={Logo} alt="Logo" />
        </div>
      </div>
    </header>
  )
}

export default Header
