import React from "react";
import Logo from '../../images/bee-driving-loop.gif'

export default function LoadingSpinner() {
  return (
    <div className="spinner">
      <div className="loading">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="background"></div>
    </div>
  );
}