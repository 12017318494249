import { AiOutlineInstagram, AiOutlineLinkedin } from 'react-icons/ai'
import { FiTwitter } from 'react-icons/fi'
import { RiFacebookCircleLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  PRIVACY_POLICY_LINK,
  SERVICE_LEVEL_AGREEMENT_LINK,
  TERMS_OF_SERVICE_LINK,
  TWITTER_LINK,
} from '../constants/constants'

const Footer = () => {
  return (
    <footer className="footer flex flex-col items-start justify-between px-[40px] pb-[40px] pt-[26px] text-white md:h-[7rem] md:items-center md:px-6 md:py-4 lg:flex-row lg:py-0 2xl:px-12">
      {/**Mobile footer */}
      <div className="md:hidden">
        <div className="grid-row grid gap-[31px]">
          <div>
            <p className="block">Copyright © Carbeeza 2024</p>
          </div>

          <Link
            to={SERVICE_LEVEL_AGREEMENT_LINK}
            target="_blank"
            className="text-[#DED4FF] underline decoration-[#B49FFF] underline-offset-4"
          >
            Service Level Agreement
          </Link>

          <Link
            to={TERMS_OF_SERVICE_LINK}
            target="_blank"
            className="text-[#DED4FF] underline decoration-[#B49FFF] underline-offset-4"
          >
            Terms of Service
          </Link>

          <Link
            to={PRIVACY_POLICY_LINK}
            target="_blank"
            className="text-[#DED4FF] underline decoration-[#B49FFF] underline-offset-4"
          >
            Privacy Policy
          </Link>

          <p className="block">
            <span className="mr-1 text-[#FFD609]">TSXV:</span>AUTO
          </p>
          <div className="flex flex-col">
            <p>
              <span className="mb-1 text-xs text-[#FFD609]">Follow us on:</span>
            </p>
            <ul className="flex gap-4">
              <li>
                <Link to={INSTAGRAM_LINK} target="_blank">
                  <AiOutlineInstagram className="h-5 w-5" />
                </Link>
              </li>
              <li>
                <Link to={FACEBOOK_LINK} target="_blank">
                  <RiFacebookCircleLine className="h-5 w-5" />
                </Link>
              </li>
              <li>
                <Link to={TWITTER_LINK} target="_blank">
                  <FiTwitter className="h-5 w-5" />
                </Link>
              </li>
              <li>
                <Link to={LINKEDIN_LINK} target="_blank">
                  <AiOutlineLinkedin className="h-5 w-5" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/**Desktop footer */}
      <div className="hidden md:block">
        <div className="flex flex-col gap-2 md:flex-row md:gap-8">
          <p className="block">Copyright © Carbeeza 2024</p>
          <ul className="flex gap-6 text-[#DED4FF]">
            <li>
              <Link
                to={SERVICE_LEVEL_AGREEMENT_LINK}
                target="_blank"
                className="underline decoration-[#B49FFF] underline-offset-4"
              >
                Service Level Agreement
              </Link>
            </li>
            <li>
              <Link
                to={TERMS_OF_SERVICE_LINK}
                target="_blank"
                className="underline decoration-[#B49FFF] underline-offset-4"
              >
                Terms of Service
              </Link>
            </li>
            <li>
              <Link
                to={PRIVACY_POLICY_LINK}
                target="_blank"
                className="underline decoration-[#B49FFF] underline-offset-4"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <p className="block">
            <span className="mr-1 text-[#FFD609]">TSXV:</span>AUTO
          </p>
        </div>
        <div className="flex flex-col">
          <p>
            <span className="mb-1 text-xs text-[#FFD609]">Follow us on:</span>
          </p>
          <ul className="flex gap-4">
            <li>
              <Link to={INSTAGRAM_LINK} target="_blank">
                <AiOutlineInstagram className="h-5 w-5" />
              </Link>
            </li>
            <li>
              <Link to={FACEBOOK_LINK} target="_blank">
                <RiFacebookCircleLine className="h-5 w-5" />
              </Link>
            </li>
            <li>
              <Link to={TWITTER_LINK} target="_blank">
                <FiTwitter className="h-5 w-5" />
              </Link>
            </li>
            <li>
              <Link to={LINKEDIN_LINK} target="_blank">
                <AiOutlineLinkedin className="h-5 w-5" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
