import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import type { User, UserCredential } from 'firebase/auth'

import { auth } from '../services/firebase'

export const logIn = async (
  user: string,
  password: string,
  afterLogin?: (firebaseUser: User) => void
) => {
  try {
    const authResp: UserCredential = await signInWithEmailAndPassword(
      auth,
      user,
      password
    )
    const firebaseUser = authResp.user
    if (firebaseUser) {
      sessionStorage.setItem('token', firebaseUser.accessToken)

      if (afterLogin) {
        afterLogin(firebaseUser)
      }

      return
    }
    //   throw new Error(authResp.message)
  } catch (err) {
    console.error(err)
  }
}

export const logOut = async () => {
  try {
    await signOut(auth)

    sessionStorage.removeItem('user')
    sessionStorage.removeItem('selectedDealer')
    sessionStorage.removeItem('token')

    window.location.reload()

    return
  } catch (err) {
    console.error(err)
  }
}
