import DefaultLayout from '../../layout/DefaultLayout'

const ManageBilling = () => {
  return (
    <DefaultLayout>
      <div>Manage Billing</div>
    </DefaultLayout>
  )
}

export default ManageBilling
