import { useContext, useEffect, useState } from 'react'

import SuccessFailureModal from '../../components/SuccessFailureModal'
import { UserContext } from '../../context/UserContext'
import DefaultLayout from '../../layout/DefaultLayout'

import EmailAddress from './EmailAddress'
import Name from './Name'
import Password from './Password'
import UltraleadSendEmail from './UltraleadSendEmail'

const AccountSettings = () => {
  const { userData } = useContext(UserContext)
  const [ultraleadSendtEmail, setUltraleadSendtEmail] = useState(
    userData?.dealer_email?.join(', ')
  )
  const [firstName, setFirstName] = useState(userData?.firstName)
  const [lastName, setLastName] = useState(userData?.lastName)
  const [emailAddress, setEmailAddress] = useState(userData?.email)
  const [password, setPassword] = useState('password')
  const [showSuccessPasswordModal, setShowSuccessPasswordModal] =
    useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSuccessNameModal, setShowSuccessNameModal] = useState(false)
  const [showSuccessEmailModal, setShowSuccessEmailModal] = useState(false)
  const [showSuccessUltraleadEmailModal, setShowSuccessUltraleadEmailModal] =
    useState(false)
  const [showUltraleadEmailErrorModal, setShowUltraleadEmailErrorModal] =
    useState(false)
  useEffect(() => {
    changeUserData()
  }, [userData])

  const changeUserData = () => {
    setUltraleadSendtEmail(userData?.dealer_email?.join(', '))
    setFirstName(userData?.first_name)
    setLastName(userData?.last_name)
    setEmailAddress(userData?.email)
  }
  return (
    <DefaultLayout changeDealerAction={changeUserData}>
      <div className="account-settings-page h-full w-full">
        <h2 className="mb-4 text-4xl">Account Settings</h2>
        <div className="ultralead-address flex flex-col items-center justify-center py-14">
          <h3 className="align-center mb-6">
            Your Ultralead address for {userData?.dealer_name}
          </h3>
          <span className="border-b border-boundary-2 pb-[4px] text-lg text-primary">
            {userData?.dealer_inbox}
          </span>
        </div>
        <ul className="account-settings-list divide-y divide-boundary-1 border-y border-boundary-1">
          <li className="ultralead-email block items-center justify-between md:flex">
            <UltraleadSendEmail
              ultraleadSendtEmail={ultraleadSendtEmail}
              setUltraleadSendtEmail={setUltraleadSendtEmail}
              toggleSuccessModal={() => setShowSuccessUltraleadEmailModal(true)}
              toggleErrorModal={() => {
                setShowUltraleadEmailErrorModal(true)
              }}
            />
          </li>
          <li className="ultralead-email block items-center justify-between md:flex">
            <Name
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              toggleSuccessModal={() => setShowSuccessNameModal(true)}
              toggleErrorModal={() => {
                setShowErrorModal(true)
              }}
            />
          </li>
          <li className="ultralead-email block items-center justify-between md:flex">
            <EmailAddress
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
              toggleSuccessModal={() => setShowSuccessEmailModal(true)}
              toggleErrorModal={() => {
                setShowErrorModal(true)
              }}
            />
          </li>
          <li className="ultralead-email block items-center justify-between md:flex">
            <Password
              password={password}
              setPassword={setPassword}
              toggleSuccessModal={() => setShowSuccessPasswordModal(true)}
              toggleErrorModal={() => {
                setShowErrorModal(true)
              }}
            />
          </li>
        </ul>
      </div>
      {showSuccessPasswordModal && (
        <SuccessFailureModal
          title="Success!"
          message={{
            __html: '<div>Your Ultralead password has been updated.</div>',
          }}
          buttonText="Done"
          buttonAction={() => setShowSuccessPasswordModal(false)}
          onClose={() => setShowSuccessPasswordModal(false)}
        />
      )}
      {showSuccessNameModal && (
        <SuccessFailureModal
          title="Success!"
          message={{
            __html: '<div>Your Name has been updated.</div>',
          }}
          buttonText="Done"
          buttonAction={() => setShowSuccessNameModal(false)}
          onClose={() => setShowSuccessNameModal(false)}
        />
      )}
      {showSuccessEmailModal && (
        <SuccessFailureModal
          title="Success!"
          message={{
            __html: '<div>Your Email Address has been updated.</div>',
          }}
          buttonText="Done"
          buttonAction={() => setShowSuccessEmailModal(false)}
          onClose={() => setShowSuccessEmailModal(false)}
        />
      )}
      {showSuccessUltraleadEmailModal && (
        <SuccessFailureModal
          title="Success!"
          message={{
            __html: `<div>UltraLeads for ${userData.dealer_name} will now be sent to ${ultraleadSendtEmail}</div>`,
          }}
          buttonText="Done"
          buttonAction={() => setShowSuccessUltraleadEmailModal(false)}
          onClose={() => setShowSuccessUltraleadEmailModal(false)}
        />
      )}
      {showUltraleadEmailErrorModal && (
        <SuccessFailureModal
          title="Hmm…"
          message={{
            __html:
              '<div>Something went wrong while updating your Lead Delivery Address. Please try again. If the problem persists, please <a href="mailto:support@carbeeza.com" class="text-[#9475FF]">contact support.</a></div>',
          }}
          buttonText="Back"
          buttonAction={() => {
            setShowUltraleadEmailErrorModal(false)
          }}
          onClose={() => {
            setShowUltraleadEmailErrorModal(false)
          }}
        />
      )}
      {showErrorModal && (
        //href="mailto:support@carbeeza.com"
        <SuccessFailureModal
          title="Hmm…"
          message={{
            __html:
              '<div>Something went wrong. Please try again. If the problem persists, please <a href="mailto:support@carbeeza.com" class="text-[#9475FF]">contact support.</a></div>',
          }}
          buttonText="Back"
          buttonAction={() => {
            setShowErrorModal(false)
          }}
          onClose={() => {
            setShowErrorModal(false)
          }}
        />
      )}
    </DefaultLayout>
  )
}

export default AccountSettings
