import { useState, useEffect, useMemo, useContext, useRef } from 'react'
import type { SyntheticEvent } from 'react'
import type { Column } from 'react-table'

import Loading from '../../components/Loader'
import SuccessFailureModal from '../../components/SuccessFailureModal'
import Table from '../../components/Table'
import { UserContext } from '../../context/UserContext'
import DefaultLayout from '../../layout/DefaultLayout'
import UsersService from '../../services/users'

import User from './User'

interface UserRecord {
  id: number
  first_name: string
  last_name: string
  email: string
  created_at: string
}

// BUG: Figure out why API call is using unset user context initially (resulting in 500 errors) before resolving correctly
// TODO: Clean up remaining linting errors (mostly around types of userData and UserService resp types)
const ManageUsers = () => {
  const { userData } = useContext(UserContext)
  const [users, setUsers] = useState<UserRecord[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [pageSize, setPageSize] = useState(25)
  const [pageNumber, setPageNumber] = useState(1)

  // Get Users
  const getUserData = async (limit?: number, page?: number) => {
    const tableData: UserRecord[] = []

    // Set Loading
    setIsLoading(true)

    // Get User Data
    const userServiceResp = await UsersService.getUsers(
      userData.token,
      sessionStorage.getItem('token')!,
      limit,
      page
    )

    const resp: unknown = await userServiceResp?.json()

    if (resp?.data) {
      resp.data.forEach((item: any) => {
        tableData.push({
          created_at: item.created_at,
          email: item.email,
          first_name: item.first_name ? item.first_name : '-',
          id: item.id,
          last_name: item.last_name ? item.last_name : '-',
        })
      })
      setTotalCount(resp?.total_count)
    }

    // Set User Data
    setUsers(tableData)

    // Finish Loading
    setIsLoading(false)
  }

  useEffect(() => {
    async function getUserDataFn() {
      await getUserData()
    }
    void getUserDataFn()
  }, [showSuccessModal])

  // Setup Columns
  const columns: Column[] = useMemo(() => {
    return [
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'User ID',
        accessor: 'id',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
      },
    ]
  }, [])

  const handleOpenModal = (e?: SyntheticEvent) => {
    e?.stopPropagation()
    setIsOpen(true)
  }

  const handleCloseModal = (e?: SyntheticEvent) => {
    e?.stopPropagation()
    setIsOpen(false)
  }

  const toggleSuccessModal = () => {
    setShowSuccessModal(true)
  }

  const toggleErrorModal = () => {
    setShowErrorModal(true)
  }

  const changeResponseSize = async (size: number) => {
    await getUserData(size, pageNumber)
    setPageSize(size)
  }
  const nextPageNumber = async () => {
    const nextPage = pageNumber + 1
    await getUserData(pageSize, nextPage)
    setPageNumber(nextPage)
  }
  const previousPageNumber = async () => {
    const previousPage = pageNumber - 1
    await getUserData(pageSize, previousPage)
    setPageNumber(previousPage)
  }
  const goToFirstPage = async () => {
    await getUserData(pageSize, 1)
    setPageNumber(1)
  }
  const goToLastPage = async () => {
    const LastPageNumber = Math.ceil(totalCount / pageSize)
    await getUserData(pageSize, LastPageNumber)
    setPageNumber(LastPageNumber)
  }

  //   debugger

  return (
    <DefaultLayout changeDealerAction={() => void getUserData()}>
      <div className="user-page h-full w-full">
        {isLoading ? <Loading /> : ''}
        <h2 className="mb-4 text-4xl">User Management</h2>
        <button
          className="btn-primary flex w-32 items-center justify-center rounded-lg py-2"
          onClick={handleOpenModal}
        >
          Create User
        </button>
        <br />
        <div className="log-table-wrapper mt-5">
          <Table
            totalCount={totalCount}
            data={users}
            columns={columns}
            pageNumber={pageNumber}
            changeResponseSize={changeResponseSize}
            nextPageNumber={nextPageNumber}
            previousPageNumber={previousPageNumber}
            goToFirstPage={goToFirstPage}
            goToLastPage={goToLastPage}
          />
        </div>
      </div>
      {isOpen ? (
        <User
          onClose={handleCloseModal}
          toggleSuccessModal={toggleSuccessModal}
          toggleErrorModal={toggleErrorModal}
        />
      ) : (
        ''
      )}
      {showSuccessModal && (
        <SuccessFailureModal
          title="User Created"
          message={{
            __html: '<div>New user has successfully been created.</div>',
          }}
          buttonText="Done"
          buttonAction={() => {
            setShowSuccessModal(false)
          }}
          onClose={() => {
            setShowSuccessModal(false)
          }}
        />
      )}
      {showErrorModal && (
        <SuccessFailureModal
          title="Hmm…"
          message={{
            __html:
              '<div>Something went wrong. Please try again. If the problem persists, please contact support.</div>',
          }}
          buttonText="Back"
          buttonAction={() => {
            setShowErrorModal(false)
          }}
          onClose={() => {
            setShowErrorModal(false)
          }}
        />
      )}
    </DefaultLayout>
  )
}

export default ManageUsers
