import { useState, useEffect, useContext } from 'react'

import ErrorAlert from '../../components/ErrorAlert'
import Modal from '../../components/Modal'
import UsersService from '../../services/users'
import { validateEmail } from '../../utils/email.utils'
import { UserContext } from '../../context/UserContext'

interface Props {
  ultraleadSendtEmail: string
  setUltraleadSendtEmail: (email: string) => void
  toggleSuccessModal: () => void
  toggleErrorModal: () => void
}

const UltraleadSendEmail = ({
  ultraleadSendtEmail,
  setUltraleadSendtEmail,
  toggleSuccessModal,
  toggleErrorModal,
}: Props) => {
  const { userData, setUserData } = useContext(UserContext)

  const [isOpen, setIsOpen] = useState(false)

  const [currentEmail, setCurrentEmail] = useState(ultraleadSendtEmail)
  const [newEmail, setNewEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')

  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if (showError) {
      setShowError(false)
    }
    if (newEmail === '' || confirmEmail === '') {
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
  }, [newEmail, confirmEmail])

  const onChangeCurrentEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(e.target.value)
  }

  const onChangeNewEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(e.target.value)
  }

  const onChangeConfirmEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmEmail(e.target.value)
  }

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsOpen(true)
  }

  const reset = () => {
    setNewEmail('')
    setConfirmEmail('')
    setErrorMsg('')
    setSubmitDisabled(false)
    setShowError(false)
  }

  const handleCloseModal = () => {
    reset()
    setIsOpen(false)
  }

  const handleSave = () => {
    if (newEmail === confirmEmail) {
      if (!!!validateEmail(newEmail)) {
        setShowError(true)
        setSubmitDisabled(false)
        return
      }
      setUltraleadSendtEmail(newEmail)
      const selectedDealer = JSON.parse(
        sessionStorage.getItem('selectedDealer')
      )
      let data = new FormData()
      data.append('email', newEmail)
      UsersService.updateSendEmail(
        selectedDealer.value,
        data,
        userData.token,
        sessionStorage.getItem('token')!
      )
        .then((resp) => resp.json())
        .then((resp) => {
          // Close Modal
          toggleSuccessModal()
          handleCloseModal()
        })
        .catch(() => {
          toggleErrorModal()
        })

      setCurrentEmail(newEmail)
    } else {
      setShowError(true)
      setErrorMsg('The email addresses you have entered do not match')
    }
  }

  return (
    <>
      <p className="setting-name">Send Ultralead Email</p>
      <div className="flex items-center justify-between py-4 md:justify-center">
        <p className="setting-content mr-8">{ultraleadSendtEmail}</p>
        <button
          className="border-b border-boundary-2 text-lg leading-tight text-paragraph"
          onClick={handleOpenModal}
        >
          Edit
        </button>

        <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <h3 className="text-gray-900 mb-8 text-3xl font-medium leading-6">
            Change where Ultralead sends email to
          </h3>
          <div className="mb-16">
            {errorMsg !== '' && <ErrorAlert msg={errorMsg} />}
            <div className="mb-6">
              <label
                htmlFor="input-ultralead-send-current-email"
                className="mb-1 block text-left text-paragraph"
              >
                Current Email
              </label>
              <input
                id="input-ultralead-send-current-email"
                type="text"
                placeholder="Enter where ultralead sends email to"
                value={currentEmail}
                className="w-full rounded bg-transparent px-5 py-2 outline-none transition disabled:cursor-default"
                disabled
                onChange={onChangeCurrentEmail}
              />
            </div>
            <div className={`mb-6 ${showError && 'wrong-input'}`}>
              <label
                htmlFor="input-ultralead-send-new-email"
                className="mb-1 block text-left text-paragraph"
              >
                New Email
              </label>
              <input
                id="input-ultralead-send-new-email"
                type="text"
                placeholder="Enter new email address"
                value={newEmail}
                className="w-full rounded bg-transparent px-5 py-2 outline-none transition disabled:cursor-default"
                onChange={onChangeNewEmail}
              />
            </div>
            <div className={`${showError && 'wrong-input'}`}>
              <label
                htmlFor="input-ultralead-send-confirm-email"
                className="mb-1 block text-left text-paragraph"
              >
                Confirm Email
              </label>
              <input
                id="input-ultralead-send-confirm-email"
                type="text"
                placeholder="Confirm new email address"
                value={confirmEmail}
                className="w-full rounded bg-transparent px-5 py-2 outline-none transition disabled:cursor-default"
                onChange={onChangeConfirmEmail}
              />
            </div>
          </div>
          <div className="modal-button-group flex justify-end gap-6">
            <button
              type="button"
              className="btn-secondary flex w-32 items-center justify-center rounded-lg py-2"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-primary flex w-32 items-center justify-center rounded-lg py-2"
              disabled={showError || submitDisabled}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default UltraleadSendEmail
