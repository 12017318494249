import { apiRequest } from '../utils/apiRequest.utils'

const URL = import.meta.env.VITE_LEAD_DETAIL_API_URL

/**
 * [getUltraleads - Get Leads List]
 */
const getUltraleads = (
  token: string,
  filter: any,
  firebase_token: string,
  limit?: number,
  page?: number
) => {
  let orderBy = filter ? filter : ''
  let order = orderBy.includes('_asc') ? 'asc' : 'desc'

  orderBy = orderBy.replace('_asc', '')
  orderBy = orderBy.replace('_desc', '')

  return apiRequest(
    `${URL}/api/ultraleads?token=${token}&order=${order}&order_by=${orderBy}${
      limit ? '&limit=' + limit : ''
    }${page ? '&page=' + page : ''}`,
    {
      headers: {
        Authorization: `Bearer ${firebase_token}`,
      },
    }
  )
}

export default { getUltraleads }
