import { useState, useEffect, useRef } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

export interface DropdownOption {
  label: string
  value: string
  icon?: string | undefined
  token: string
}

interface DropdownProps {
  options: DropdownOption[]
  defaultTitle: string
  defaultOption?: DropdownOption
  collapseClassName: string
  onChangeOption: (option: DropdownOption) => void
}

const Dropdown = ({
  options,
  defaultTitle,
  defaultOption,
  collapseClassName,
  onChangeOption,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<DropdownOption>(
    defaultOption ? defaultOption : { icon: undefined, label: '', value: '' }
  )
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [dropdownPosition, setDropdownPosition] = useState<'above' | 'below'>(
    'below'
  )

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (option: DropdownOption) => {
    setSelectedOption(option)
    setIsOpen(false)

    onChangeOption(option)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current?.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    if (dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight

      if (dropdownRect.bottom + 200 > windowHeight) {
        setDropdownPosition('above')
      } else {
        setDropdownPosition('below')
      }
    }
  }, [isOpen])

  return (
    <div className="relative" ref={dropdownRef}>
      <div className={collapseClassName} onClick={toggleDropdown}>
        <span className="mr-1 truncate">
          {selectedOption.label ? selectedOption.label : defaultTitle}
        </span>
        {isOpen ? (
          <BiChevronUp className="absolute right-6 h-5 w-5 transition duration-300 ease-in-out" />
        ) : (
          <BiChevronDown className="absolute right-6 h-5 w-5 transition duration-300 ease-in-out" />
        )}
      </div>
      {isOpen && (
        <div
          className={`dropdown-content absolute z-10 my-1 max-h-[80vh] w-full overflow-auto rounded border-2 bg-white p-5 transition duration-300 ease-in-out ${
            dropdownPosition === 'above' ? 'bottom-full' : 'top-full'
          }`}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className={`text-p ragraph block p-2 ${
                selectedOption.value == option.value
                  ? 'bg-sidebar-bk font-medium'
                  : ''
              } cursor-pointer hover:bg-sidebar-bk`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Dropdown
