import DefaultLayout from '../../layout/DefaultLayout'

const DealerResources = () => {
  const DealerLink = ({ title, url }) => {
    return (
      <div className="mb-[14px] rounded-[4px] bg-[#EFEBFC] px-[20px] py-[13px]">
        <a
          className="text-[16px] font-normal text-[#002257]"
          href={url}
          target="_blank"
        >
          {title}
        </a>
      </div>
    )
  }
  return (
    <DefaultLayout>
      <div className="dealer-resources-page h-full w-full">
        <h2 className="mb-[63px] text-4xl ">Dealer Resources</h2>
        <div className="mb-[72px]">
          <h3 className="mb-[14px]">About Carbeeza & Our Products</h3>
          <DealerLink
            title="UltraLead Website"
            url="http://www.ultralead.ai/"
          />
          <DealerLink title="UltraLead Plans" url="http://ultralead.ai/plans" />
          <DealerLink
            title="Carbeeza Website"
            url="https://corp.carbeeza.com/"
          />
          <DealerLink
            title="Webinar Registration"
            url="https://corp.carbeeza.com/webinar-schedule"
          />
        </div>
        <div className="mb-[72px]">
          <h3 className="mb-[14px]">UltraLead Resources</h3>
          <DealerLink
            title="Setup Instructions"
            url="https://support.ultralead.ai/en/support/solutions/articles/67000719921-set-up-instructions"
          />
          <DealerLink
            title="FAQ"
            url="https://support.ultralead.ai/en/support/solutions/articles/67000719889-ultralead-faq"
          />
          <DealerLink
            title="Helpdesk & Support"
            url="https://support.ultralead.ai/support/home"
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default DealerResources
