import { useState, useEffect, useContext } from 'react'

import Modal from '../../components/Modal'
import { UserContext } from '../../context/UserContext'
import UsersService from '../../services/users'

interface Props {
  firstName: string
  setFirstName: (name: string) => void
  lastName: string
  setLastName: (name: string) => void
  toggleSuccessModal: () => void
  toggleErrorModal: () => void
}

const Name = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  toggleSuccessModal,
  toggleErrorModal,
}: Props) => {
  const { userData, setUserData } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)

  const [fName, setFName] = useState('')
  const [lName, setLName] = useState('')

  const [submitDisabled, setSubmitDisabled] = useState(false)

  useEffect(() => {
    if (isEmptyOrSpaces(fName) || isEmptyOrSpaces(lName)) {
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
  }, [fName, lName])

  const reg = /^[A-Za-z\s]*$/

  const isEmptyOrSpaces = (str: string) => {
    return str === null || str?.match(/^ *$/) !== null
  }

  const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!reg.test(e.target.value)) {
      return
    }
    setFName(e.target.value)
  }

  const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!reg.test(e.target.value)) {
      return
    }
    setLName(e.target.value)
  }

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsOpen(true)
  }

  const reset = () => {
    setFName('')
    setLName('')
    setSubmitDisabled(false)
  }

  const handleCloseModal = () => {
    reset()
    setIsOpen(false)
  }

  const handleSave = () => {
    let data = new FormData()
    setSubmitDisabled(true)
    // Add Form Props
    data.append('first_name', fName)
    data.append('last_name', lName)

    // Update User
    UsersService.updateUserName(
      userData.uid,
      data,
      userData.token,
      sessionStorage.getItem('token')!
    )
      .then((resp) => resp.json())
      .then(() => {
        toggleSuccessModal()
        handleCloseModal()
        setFirstName(fName)
        setLastName(lName)
        setSubmitDisabled(false)
      })
      .catch(() => {
        toggleErrorModal()
        setSubmitDisabled(false)
      })
  }

  return (
    <>
      <p className="setting-name">Name</p>
      <div className="flex items-center justify-between py-4 md:justify-center">
        <p className="setting-content mr-8">
          {firstName}&nbsp;{lastName}
        </p>
        <button
          className="border-b border-boundary-2 text-lg leading-tight text-paragraph"
          onClick={handleOpenModal}
        >
          Edit
        </button>

        <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <h3 className="text-gray-900 mb-8 text-3xl font-medium leading-6">
            Update Name
          </h3>
          <form>
            <div className="mb-16">
              <div className="mb-6">
                <label
                  htmlFor="input-first-name"
                  className="mb-1 block text-left text-paragraph"
                >
                  First Name
                </label>
                <input
                  id="input-first-name"
                  type="text"
                  placeholder="Enter first name"
                  value={fName}
                  className={`w-full rounded bg-transparent px-5 py-2 outline-none transition disabled:cursor-default`}
                  onChange={onChangeFirstName}
                />
              </div>
              <div className={`mb-6`}>
                <label
                  htmlFor="input-last-name"
                  className="mb-1 block text-left text-paragraph"
                >
                  Last Name
                </label>
                <input
                  id="input-last-name"
                  type="text"
                  placeholder="Enter new email address"
                  value={lName}
                  className="w-full rounded bg-transparent px-5 py-2 outline-none transition disabled:cursor-default"
                  onChange={onChangeLastName}
                />
              </div>
            </div>
          </form>
          <div className="modal-button-group flex justify-end gap-6">
            <button
              type="button"
              className="btn-secondary flex w-32 items-center justify-center rounded-lg py-2"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-primary flex w-32 items-center justify-center rounded-lg py-2"
              disabled={submitDisabled}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Name
