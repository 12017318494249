import Modal from '../../components/Modal'

interface Props {
  title: string
  message: { __html: string | TrustedHTML }
  buttonText: string
  buttonAction: () => void
  onClose: () => void
}

const SuccessFailureModal = ({
  title,
  message,
  buttonText,
  buttonAction,
  onClose,
}: Props) => {
  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <h3 className="mb-8 text-3xl font-medium leading-6 text-[#3B2F66]">
          {title}
        </h3>
        <div
          className="mb-[127px] text-[20px] text-[#3B2F66]"
          dangerouslySetInnerHTML={message}
        ></div>
        <div className="modal-button-group flex justify-end gap-6">
          <button
            type="submit"
            className="btn-primary flex  items-center justify-center rounded-lg px-[30px] py-[18px]"
            onClick={buttonAction}
          >
            {buttonText}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default SuccessFailureModal
