import {
  MdFirstPage,
  MdLastPage,
  MdNavigateBefore,
  MdNavigateNext,
} from 'react-icons/md'
import type { Column } from 'react-table'
import { useTable, usePagination } from 'react-table'
import { isMobile } from 'react-device-detect'
import loader from '../../images/loader-gif.gif'
import type { DropdownOption } from '../Dropdown'
import Dropdown from '../Dropdown'

interface TableProps<T extends object> {
  columns: Column<T>[]
  data: T[]
  totalCount?: number
  changeResponseSize?: any
  nextPageNumber?: any
  previousPageNumber?: any
  goToFirstPage?: any
  pageNumber?: number
  goToLastPage?: any
  isLoading?: boolean
}

const initialPageIndex = 0
const initialPageSize = isMobile ? 10 : 25
const defaultDropdown = {
  label: String(initialPageSize),
  value: String(initialPageSize),
}

const Table = <T extends Object>({
  columns,
  data,
  totalCount,
  changeResponseSize,
  nextPageNumber,
  previousPageNumber,
  goToFirstPage,
  pageNumber = 1,
  goToLastPage,
  isLoading,
}: TableProps<T>) => {
  const getPageSizes = () => {
    const pageSizes = [
      {
        label: '10',
        value: '10',
      },
      {
        label: '25',
        value: '25',
      },
      {
        label: '50',
        value: '50',
      },
      {
        label: '100',
        value: '100',
      },
    ]

    if (isMobile) {
      return pageSizes
    }

    return pageSizes.filter((size) => size.value !== '10')
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: initialPageIndex,
        pageSize: initialPageSize,
      },
    },
    usePagination
  )

  const startIndex = pageIndex * pageSize + 1
  const endIndex =
    startIndex - 1 + pageSize > totalCount
      ? totalCount
      : startIndex - 1 + pageSize

  // Render the UI for your table and the styles
  // FIXME: Move key prop out of destructuring of getTableProps to remove warning (see console)
  return (
    <div className="flex flex-col">
      <table
        {...getTableProps()}
        className="block overflow-y-auto whitespace-nowrap border-b border-boundary-1 text-text lg:table"
      >
        <thead className="bg-sidebar-bk text-paragraph">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="break-all px-4 py-4 text-left font-medium tracking-wider"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-boundary-1">
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className="odd:bg-[#F9F7FF] even:bg-white"
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="break-all px-4 py-4"
                    >
                      {cell.render('Cell', { uid: row })}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {isLoading && (
        <div className="flex h-full w-full justify-center">
          <div className="mt-[100px] flex flex-col">
            <div>
              <span className="text-[20px] text-[#3B2F66]">Working...</span>
            </div>
            <div>
              <img
                src={loader}
                alt="loader"
                className="h-[98px] w-[98px] m-auto"
              />
            </div>
          </div>
        </div>
      )}
      <div className="pagination flex flex-col items-center justify-end px-5 pb-3 pt-5 text-paragraph md:flex-row">
        <span className="md:mr-8">Rows per page</span>
        <Dropdown
          options={getPageSizes()}
          defaultTitle="Select Page Size"
          defaultOption={defaultDropdown}
          collapseClassName="w-20 px-3 py-2 rounded inline-flex items-center cursor-pointer transition duration-300 ease-in-out border border-paragraph/60"
          onChangeOption={(option: DropdownOption) => {
            changeResponseSize(option.value)
            setPageSize(Number(option.value))
          }}
        />
        <span className="md:ml-8">
          {pageNumber * pageSize - pageSize + 1} -{' '}
          {pageNumber * pageSize - pageSize + data.length} of {totalCount}
        </span>
        <div className="pagination -button-group flex gap-4 md:ml-12">
          <button
            onClick={() => {
              goToFirstPage()
              gotoPage(0)
            }}
            disabled={pageNumber == 1}
            className="disabled:text-gray"
          >
            <MdFirstPage className="h-6 w-6" />
          </button>
          <button
            onClick={() => {
              previousPageNumber()
              previousPage()
            }}
            disabled={pageNumber == 1}
            className="disabled:text-gray"
          >
            <MdNavigateBefore className="h-6 w-6" />
          </button>
          <button
            onClick={() => {
              nextPageNumber()
              nextPage()
            }}
            disabled={data.length < pageSize}
            className="disabled:text-gray"
          >
            <MdNavigateNext className="h-6 w-6" />
          </button>
          <button
            onClick={() => {
              goToLastPage()
              gotoPage(pageCount - 1)
            }}
            disabled={data.length < pageSize}
            className="disabled:text-gray"
          >
            <MdLastPage className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Table
