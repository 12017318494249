// Routes
export const ROUTE_SIGN_IN = 'signin'
export const ROUTE_LEAD_DETAILS = 'lead/:id'
export const ROUTE_DEALER_RESOURCES = 'dealerresources'
export const ROUTE_REDIRECT = 'redirect'
export const ROUTE_LEAD_LOG = 'leadlog'
export const ROUTE_ACCOUNT_SETTINGS = 'accountsettings'
export const ROUTE_MANAGE_BILLING = 'managebilling'
export const ROUTE_MANAGE_USERS = 'manageUsers'
//Footer Links
export const SERVICE_LEVEL_AGREEMENT_LINK =
  'https://www.ultralead.ai/s/UltraLeadSLA.pdf'
export const TERMS_OF_SERVICE_LINK =
  'https://carbeeza.com/dealer-terms-of-service'
export const PRIVACY_POLICY_LINK = 'https://carbeeza.com/privacy-policy'
export const INSTAGRAM_LINK = 'https://www.instagram.com/carbeeza/'
export const FACEBOOK_LINK = 'https://www.facebook.com/Carbeeza/'
export const TWITTER_LINK = 'https://twitter.com/Carbeeza'
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/carbeeza'
