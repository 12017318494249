import type { ReactNode } from 'react'

import Footer from './Footer'
import LeadHeader from './LeadHeader'

interface LeadLayoutProps {
  children: ReactNode
}

const LeadLayout = ({ children }: LeadLayoutProps) => {
  return (
    <div id="wrapper">
      <LeadHeader />
      <div className="main-content relative flex bg-[#F6F4FF] bg-sidebar-bk">
        <main className="flex flex-1 p-[20px] pt-[15px]">{children}</main>
      </div>
      <Footer />
    </div>
  )
}

export default LeadLayout
