import { Link } from 'react-router-dom'

import Logo from '../images/ultralead-branding-white.svg'

const LeadHeader = () => {
  return (
    <header className="header sticky top-0 z-50 flex h-[5rem] items-center">
      <div className="flex flex-grow items-center justify-between px-4 px-6 2xl:pl-12">
        <div className="m-auto flex items-center md:hidden">
          <Link to="/" className="logo block">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="hidden items-center md:flex">
          <Link to="/" className="logo block">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
      </div>
    </header>
  )
}

export default LeadHeader
