import { useState, useEffect, useRef } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

export interface AvatarDropdownOption {
  label: string
  value: string
  slug: string
}

interface AvatarDropdownProps {
  options: AvatarDropdownOption[]
  defaultTitle: string | undefined
  defaultName: string
  defaultOption?: AvatarDropdownOption
  collapseClassName: string
  onChangeOption: (option: string) => void
}

const AvatarDropdown = ({
  options,
  defaultTitle,
  defaultName,
  defaultOption,
  collapseClassName,
  onChangeOption,
}: AvatarDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<AvatarDropdownOption>(
    defaultOption ? defaultOption : { label: '', slug: '', value: '' }
  )
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [dropdownPosition, setDropdownPosition] = useState<'above' | 'below'>(
    'below'
  )

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (option: AvatarDropdownOption) => {
    setSelectedOption(option)
    setIsOpen(false)

    onChangeOption(option.slug)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current?.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    if (dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight

      if (dropdownRect.bottom + 200 > windowHeight) {
        setDropdownPosition('above')
      } else {
        setDropdownPosition('below')
      }
    }
  }, [isOpen])

  const getAvatarInitials = (name: string) => {
    if (!name) {
      return
    }
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

    const initials = [...name.matchAll(rgx)] || []

    return (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase()
  }
  return (
    <div className="relative" ref={dropdownRef}>
      <div className={collapseClassName} onClick={toggleDropdown}>
        <div className="avatar-wrapper mr-5 h-[44px] w-[44px] cursor-pointer rounded-full p-[2px]">
          <div className="avatar-inner-wrapper h-full w-full rounded-full">
            <div className="avatar flex h-full w-full items-center justify-center font-bold text-paragraph">
              {defaultTitle ? (
                <img src={defaultTitle} alt="avatar" className="rounded-3xl" />
              ) : (
                getAvatarInitials(defaultName)
              )}
            </div>
          </div>
        </div>
        {isOpen ? (
          <BiChevronUp className="absolute right-0 h-5 w-5 text-white transition duration-300 ease-in-out" />
        ) : (
          <BiChevronDown className="absolute right-0 h-5 w-5 text-white transition duration-300 ease-in-out" />
        )}
      </div>
      {isOpen && (
        <div
          className={`dropdown-content absolute right-0 z-10 my-1 w-[240px] rounded border-2 bg-white p-5 transition duration-300 ease-in-out ${
            dropdownPosition === 'above' ? 'bottom-full' : 'top-full'
          }`}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className={`text-p ragraph block p-2 ${
                selectedOption.value == option.value
                  ? 'bg-sidebar-bk font-medium'
                  : ''
              } cursor-pointer hover:bg-sidebar-bk`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AvatarDropdown
