import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Constants from '../../constants/constants'
import DefaultLayout from '../../layout/DefaultLayout'

const RedirectPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/' + Constants.ROUTE_LEAD_LOG)
  })

  return (
    <DefaultLayout>
      <div></div>
    </DefaultLayout>
  )
}

export default RedirectPage
