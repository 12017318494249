import { MdClose } from 'react-icons/md'

interface Props {
  msg: string
}

const ErrorAlert = ({ msg }: Props) => {
  return (
    <div
      className={`error-msg justify-content itmes-center relative mb-8 flex rounded-md border border-[#F7858F] bg-error-bk p-8 text-error`}
    >
      <div className="absolute right-2 top-2">
        <MdClose className="h-4 w-4" />
      </div>
      <p className="text-sm">{msg}</p>
    </div>
  )
}

export default ErrorAlert
