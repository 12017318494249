const AccountSettingsIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 9.5H15.5" stroke="currentColor" strokeWidth="2" />
      <path d="M0.5 3.5H15.5" stroke="currentColor" strokeWidth="2" />
      <g filter="url(#filter0_d_75_8916)">
        <circle cx="11.5" cy="9.5" r="2.5" fill="#8874C3" />
      </g>
      <g filter="url(#filter1_d_75_8916)">
        <circle cx="5.5" cy="3.5" r="2.5" fill="#8874C3" />
      </g>
      <defs>
        <filter
          id="filter0_d_75_8916"
          x="7"
          y="6"
          width="9"
          height="9"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.115166 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_75_8916"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_75_8916"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_75_8916"
          x="1"
          y="0"
          width="9"
          height="9"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.115166 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_75_8916"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_75_8916"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default AccountSettingsIcon
