import type { Dispatch, SetStateAction } from 'react'
import { createContext } from 'react'

export type GlobalContent = {
  setUserData: Dispatch<SetStateAction<{}>>
  userData: any
}

export const UserContext = createContext<GlobalContent>({
  setUserData: () => {},
  userData: {},
})
